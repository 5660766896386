import { EkukoIntranetLink } from '../interfaces/EkukoIntranetLink.interface';

interface props {
  officeId: number;
  listOfLinks: EkukoIntranetLink[];
}

function ekukoIntranetLinkForOffice({ officeId, listOfLinks }: props) {
  for (let index = 0; index < listOfLinks.length; index++) {
    if (listOfLinks[index].id === officeId) {
      return listOfLinks[index].link;
    }
  }
}

export default ekukoIntranetLinkForOffice;
