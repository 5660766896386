import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PrimaryButtonMedium from '../../UI/PrimaryButtonMedium';
import DeleteDialog from '../DeleteDialog';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import TempAgentInterface from '../../../interfaces/TempAgent.interface';
import deleteExternAgent from '../../../utilites/deleteExternAgent';
import ButtonBack from '../../UI/ButtonBack';

const useStyles = makeStyles((theme) => ({
  caption: {
    color: '#333333',
    fontSize: '28px',
    lineHeight: '32px',
    fontFamily: "'Poppins', sans-serif",
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '570px',
  },
  RowBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginBottom: '10px',
    },
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    background: '#FFF',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
    marginTop: '30px',
    marginBottom: '8px',
  },
}));

interface ComponentInput {
  list: Array<TempAgentInterface>;
  getSelectedItem?: Function;
  handleOpenEdit?: Function;
  handleEditMode?: Function;
  triggerReload?: Function;
}
export default function TempAgentTable({
  list,
  getSelectedItem,
  handleOpenEdit,
  handleEditMode,
  triggerReload,
}: ComponentInput) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box>
      <Box>
        {/* <div>
          <IconButton
            color="primary"
            onClick={() => {
              history.push('/mypage/moderation');
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        </div> */}
        <ButtonBack link='/mypage' />
      </Box>
      <Box className={classes.RowBox}>
        <Typography className={classes.caption} component='span'>
          Externe Agenten
        </Typography>
        <PrimaryButtonMedium
          onClick={() => {
            if (handleOpenEdit) handleOpenEdit(true);
          }}
        >
          + Agenten Hinzufügen
        </PrimaryButtonMedium>
      </Box>
      <Box className={classes.table}>
        {list.map((item: TempAgentInterface, index) => {
          return (
            <Box key={index} className={classes.tableRow}>
              <Box>
                {item.name} {item.surname}
              </Box>
              <Box style={{ display: 'flex' }}>
                <EditOutlinedIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (getSelectedItem) {
                      getSelectedItem(item);
                      if (handleOpenEdit) handleOpenEdit(true);
                      if (handleEditMode) handleEditMode(true);
                    }
                  }}
                />
                <DeleteDialog
                  item={{
                    id: item._id || '',
                    name: item.name,
                    surname: item.surname,
                  }}
                  triggerReload={triggerReload}
                  deleteFunction={deleteExternAgent}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
