import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import ThemeOption from "../../utilites/config/themeOption";
// import Cover from "../../static/cover.png";
import { imageServerUrl } from "../../utilites/config/config";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { minImagesStory } from "../../utilites/config/config";

const useStyles = makeStyles((theme) => ({
  ContentBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    background: "#d8d8d8",
    backgroundImage: (props) => {
      if (props.image) {
        const blobImageUrl = props.image;
        if (blobImageUrl.includes("blob")) {
          return `url(${props.image})`;
        } else {
          return `url(${imageServerUrl + props.image})`;
        }
      }
    },
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  ImageBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  OptionBox: {
    display: (props) => {
      if (props.isRedactor) {
        return "none";
      } else {
        return "flex";
      }
    },
    width: "100%",
    height: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    transition: "all 0.5s ease-out",
    opacity: 0,
    "&:hover": {
      opacity: 1,
      transition: "all 0.5s ease-out",
    },
  },
  controlElement: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "34px",
    background: "rgba(23, 66, 99, 0.5)",
    boxSizing: "border-box",
    padding: "0 20px",
  },
  controlText: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FFFFFF",
  },
  icon: {
    display: "block",
    fontSize: "24px",
    margin: "0 3px",
    cursor: "pointer",
    color: "#ffffff",
    "&:hover": { color: "#54BFD0" },
  },
  boxRow: {
    display: "flex",
    alignItems: "center",
  },
  boxGallery: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  imageBox: {
    width: "176px",
    height: "176px",
    margin: "5px",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  boxButton: {
    marginTop: "20px",
  },
  textImageStatus: {
    color: "#D93D23",
    display: (props) => {
      if (props.counter > 0) {
        return "block";
      } else {
        return "none";
      }
    },
  },
}));

export default function ImagesStory({
  images,
  changeCurrenImageStory,
  handleDeleteImage,
  isRedactor,
}) {
  const classes = useStyles();
  const [counter, setCounter] = useState(3);

  useEffect(() => {
    setCounter(minImagesStory - images.length);
  }, [images]);

  const ImageBlock = (props) => {
    const classes = useStyles(props);
    return (
      <Box className={classes.ContentBox}>
        {images.length > 0 && (
          <Box className={classes.OptionBox}>
            <Box className={classes.controlElement}>
              <Box className={classes.boxRow}>
                <Typography className={classes.controlText} component="span">
                  {props.title}
                </Typography>
              </Box>
              <DeleteOutlineIcon
                className={classes.icon}
                onClick={() => handleDeleteImage(props.index, props.image)}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const TextStatus = (props) => {
    const classes = useStyles(props);
    return (
      <Typography className={classes.textImageStatus} component="span">
        {props.children}
      </Typography>
    );
  };

  return (
    <ThemeProvider theme={ThemeOption}>
      <ImageBlock
        isRedactor={isRedactor}
        image={images[0]}
        title="Startseite"
      />
      <Box className={classes.boxGallery}>
        {images.length > 0 &&
          images.map((image, index) => {
            return (
              <Box key={image} className={classes.imageBox}>
                <ImageBlock
                  image={image}
                  title={"Bild " + (index + 1)}
                  index={index}
                  isRedactor={isRedactor}
                />
              </Box>
            );
          })}
      </Box>
      <div className={classes.root}>
        <Box className={classes.boxButton}>
          <input
            accept="image/x-png,image/jpeg,image/jpg"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={changeCurrenImageStory}
          />
          <label htmlFor="contained-button-file">
            <Button variant="outlined" color="primary" component="span">
              Bild hinzufügen
            </Button>
          </label>
        </Box>
        <TextStatus counter={counter}>
          Fügen sie mindestens {counter} bilder hinzu
        </TextStatus>
      </div>
    </ThemeProvider>
  );
}
