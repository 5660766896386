import { url } from './config/config';

function сheckingQueryExecutionError(responceBody) {
  if (responceBody.errors) {
    let array = [];
    responceBody.errors.forEach((element) => {
      if (element.extensions) {
        array.push(element.extensions);
      } else {
        array.push(JSON.stringify({ CODE: '0000', MESSAGE: 'unknow Error' }));
      }
    });
    return { error: array };
  } else {
    return responceBody.data;
  }
}
/**
 *
 * @param {String} typeQuery
 * @param {Array | String | Object} bodys
 * @param {any} [variables]
 */
function requestToServer(typeQuery, bodys, variables) {
  try {
    if (typeQuery === 'query') {
      let queryBody = `{"query":"query{ ${bodys.toString()} }","variables":{},"operationName":null}`;
      return fetch(url, {
        method: 'POST',
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
        json: true,
        body: queryBody,
      })
        .then((responce) => responce.json())
        .then((result) => сheckingQueryExecutionError(result))
        .catch((error) => {
          console.log('requestToServer data Error:', error);
          return [];
        });
    } else if (typeQuery === 'mutation') {
      return fetch(url, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          query: bodys,
          variables: variables,
        }),
      })
        .then((responce) => responce.json())
        .then((result) => сheckingQueryExecutionError(result))
        .catch((error) => {
          console.log('requestToServer data Error:', error);
          return [];
        });
    }
  } catch (error) {
    console.log(`Method requestToServer error:`, error);
  }
}

export default requestToServer;
