import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import PrimaryButtonMedium from '../components/UI/PrimaryButtonMedium';
import LoginLogoCockpit from '../static/loginLogoCockpit.png';
import logoMUGL from '../static/logoMUGL.png';
import logoTRAV from '../static/logoTRAV.png';
import logoRITA from '../static/logoRITA.png';
import getCurrentUser from '../utilites/getCurrentUser';
import { authUser } from '../utilites/authUser';

/**
 * Function to chose logo depend on host 
 
 * @param {String} host Host of window Object
 * @default logoRITA
 * @returns {String} Name of logo
 
 */

function logoChoser(host) {
  const logoList = [logoMUGL, logoTRAV, logoRITA];
  let logo = null;
  switch (host) {
    case 'cockpit.mein-urlaubsglueck.de':
      logo = logoList[0];
      break;
    case 'cockpit.my-travelexpert.de':
      logo = logoList[1];
      break;
    default:
      logo = logoList[2];
      break;
  }
  return logo;
}

function logoWidthAndSpacing(host) {
  const widthValueList = ['12%', '12%', '8%'];
  let widthValue = null;
  const spacingValueListTop = [8, 8, 8];
  const spacingValueListRight = [8, 8, 15];
  let spacingValueTop = null;
  let spacingValueRight = null;
  switch (host) {
    case 'cockpit.mein-urlaubsglueck.de':
      widthValue = widthValueList[0];
      spacingValueTop = spacingValueListTop[0];
      spacingValueRight = spacingValueListRight[0];
      break;
    case 'cockpit.my-travelexpert.de':
      widthValue = widthValueList[1];
      spacingValueTop = spacingValueListTop[1];
      spacingValueRight = spacingValueListRight[1];
      break;
    default:
      widthValue = widthValueList[2];
      spacingValueTop = spacingValueListTop[2];
      spacingValueRight = spacingValueListRight[2];
      break;
  }
  return { widthValue, spacingValueTop, spacingValueRight };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start', // Center vertically
    backgroundImage: `url(${require('../static/images/CockpitBackground.png')})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    filter: 'brightness(0.9)',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(40),
  },
  logoTopLeft: ({ host }) => ({
    position: 'absolute',
    top: theme.spacing(logoWidthAndSpacing(host).spacingValueTop),
    right: theme.spacing(logoWidthAndSpacing(host).spacingValueRight),
    width: logoWidthAndSpacing(host).widthValue,
    height: 'auto',
  }),
  logo: {
    width: '90%',
    height: 'auto',
    marginBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(6),
  },
}));

export default function SignIn() {
  const [error, setError] = useState(false);
  const host = window.location.host;
  const classes = useStyles({ host });

  function handleSubmit(e) {
    localStorage.removeItem('token');
    e.preventDefault();
    const user = document.getElementById('login').value;
    const password = document.getElementById('password').value;

    authUser(user, password)
      .then((isAuthUser) => {
        if (isAuthUser) {
          const userData = getCurrentUser();
          localStorage.setItem('user', JSON.stringify(userData));
          window.location.replace('/mypage');
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
      });
  }

  return (
    <div className={classes.root}>
      <img
        alt='loginLogo'
        src={logoChoser(host)}
        className={classes.logoTopLeft}
      />
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <Container maxWidth='xs'>
            <img
              alt='loginLogo'
              src={LoginLogoCockpit}
              className={classes.logo}
            />
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='login'
                label='Login'
                name='login'
                autoComplete='login'
                autoFocus
                error={error}
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='current-password'
                error={error}
              />
              <Grid container justify='center' className={classes.submit}>
                <PrimaryButtonMedium
                  type='submit'
                  fullWidth
                  variant='contained'
                >
                  Anmelden
                </PrimaryButtonMedium>
              </Grid>
            </form>
          </Container>
        </div>
      </Container>
    </div>
  );
}
