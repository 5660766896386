import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import PrimaryButtonMedium from './PrimaryButtonMedium';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-${50}%, -${50}%)`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  caption: {
    color: '#333333',
    fontSize: '28px',
    lineHeight: '32px',
    fontFamily: "'Poppins', sans-serif",
  },
  ItemBox: {
    display: 'flex',
    marginBottom: '10px',
    width: '100%',
    alignItems: 'center',
  },
  ItemBoxForBtnAndText: {
    display: 'flex',
    marginBottom: '10px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  InputMultiline: {
    width: '100%',
  },
  CheckIcon: {
    fontSize: '33px',
    marginLeft: '10px',
    color: 'green',
  },
}));

function MyModal({ openMod, closeMod }) {
  const classes = useStyles();
  const [klickedSaveBtn, setKlickedSaveBtn] = useState(false);
  const [isDirty, setIsDirty] = useState(true);
  const { values, handleChange, handleBlur, initialValues, handleSubmit } =
    useFormikContext();

  const body = (
    <>
      <Grid container xs={10} item md={6} className={classes.paper}>
        <Box className={classes.ItemBoxForBtnAndText}>
          <Typography className={classes.caption} component='span'>
            Ich, über mich
          </Typography>
          <IconButton
            color='primary'
            onClick={() => {
              setKlickedSaveBtn(false);
              setIsDirty(true);
              closeMod();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid item xs={12}>
          <Box className={classes.ItemBox}>
            <TextField
              id='filled-multiline-flexible'
              className={classes.InputMultiline}
              label='Text'
              multiline
              rows={15}
              variant='filled'
              name='aboutMe'
              value={values.aboutMe}
              onChange={handleChange}
              onBlur={handleBlur}
              onInput={() => {
                if (initialValues.aboutMe === values.aboutMe) {
                  setIsDirty(true);
                } else {
                  setIsDirty(false);
                }
              }}
            />
          </Box>
          <Box className={classes.ItemBox}>
            <PrimaryButtonMedium
              disabled={isDirty}
              onClick={() => {
                handleSubmit();
                setKlickedSaveBtn(true);
              }}
            >
              Änderungen speichern{' '}
            </PrimaryButtonMedium>
            {klickedSaveBtn && (
              <CheckCircleOutlineIcon className={classes.CheckIcon} />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Modal
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      open={openMod}
    >
      <>{body}</>
    </Modal>
  );
}

export default MyModal;
