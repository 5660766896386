import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

function AlertMessage({ typeMessage, alertMessage, isAlertMessage, handleChangeIsAlertMessage }) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(isAlertMessage);
  }, [isAlertMessage]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    handleChangeIsAlertMessage();
    setOpen(false);
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={typeMessage || "info"}
      >
        {alertMessage || "Message"}
      </Alert>
    </Snackbar>
  );
}

export default AlertMessage;
