import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const CustomButton = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 14,
    padding: "8px 16px",
    lineHeight: 1.2,
    color: theme.palette.secondary.main,
    border: `2px solid ${theme.palette.secondary.main}`,
    background: "#ffffff",
    boxSizing: "border-box",
    borderRadius: theme.btn?.borderRadius,
    fontFamily: ['"Poppins", sans-serif'].join(","),
    "&:hover": {
      background: "#ffffff",
      color: theme.palette.secondary.light,
      border: `2px solid ${theme.palette.secondary.light}`,
      boxShadow: "none",
    },
    "&:active": {
      background: "#ffffff",
      color: theme.palette.secondary.dark,
      boxShadow: "none",
      border: `2px solid ${theme.palette.secondary.dark}`,
    },
    "&:focus": {},
  },
}))(Button);

export default function SecondaryButtonSmall(prop) {
  return (
    <CustomButton onClick={prop.onClick} variant="contained">
      {prop.children}
    </CustomButton>
  );
}
