import React from 'react';
import styled from 'styled-components';
import HeaderLogoAER from '../static/headerLogoAERW.png';
import HeaderLogoMUGL from '../static/headerLogoMUGLW.png';
import HeaderLogoTRAV from '../static/headerLogoTRAVW.png';
import Hourglass from '../static/images/Hourglass.png';
import Heading2 from '../components/UI/Typography/Heading2';
import Heading5 from '../components/UI/Typography/Heading5';
import TextBody18 from '../components/UI/Typography/TextBody18';

let userInfo = localStorage.getItem('user');
let userInfoAfterParse = JSON.parse(userInfo);

/**
 * Function to chose header logo depend on office_id of user

 * @param {Number} office_id
 * @default CockpitHeaderLogo
 * @returns {String} Name of header logo

 */

function logoChoser(office_id) {
  const logoList = [HeaderLogoMUGL, HeaderLogoTRAV, HeaderLogoAER];
  let logo = null;
  switch (office_id) {
    case 1:
      logo = logoList[0];
      break;
    case 2:
      logo = logoList[1];
      break;
    case 3:
      logo = logoList[2];
      break;
    default:
      logo = logoList[3];
      break;
  }
  return logo;
}

export default function ExpiredPage() {
  return (
    <Block>
      <LogoBox>
        <ImageLogo
          src={logoChoser(userInfoAfterParse?.branch_office_id)}
          alt='logo'
        />
        <img src={HeaderLogoAER} alt='logo' />
      </LogoBox>
      <BlockExpired>
        <ContentBlock>
          <Heading2 color='#333333'>Hoppla!</Heading2>
          <br /> <br />
          <Heading5 color='#B9B9B9'>Leider ist Ihr Link abgelaufen.</Heading5>
          <br /> <br />
          <TextBody18 color='#B9B9B9'>
            Bitte kontaktieren Sie Ihren Berater.
          </TextBody18>
        </ContentBlock>
        <BlockPicture>
          <Image src={Hourglass} alt='image' />
        </BlockPicture>
      </BlockExpired>
    </Block>
  );
}

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 1024px;
  background: #ffffff;
  @media (max-width: 834px) {
    height: 100%;
  }
`;

const LogoBox = styled.div`
  display: flex;
  align-items: center;
  width: 1200px;
  height: 92px;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 414px) {
    height: 72px;
  }
`;

const ImageLogo = styled.img`
  width: 212px;
  height: 60px;
  left: 16px;
  top: 16px;
  @media (max-width: 414px) {
    width: 147px;
    height: 42px;
  }
`;

const BlockExpired = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  width: 1178px;
  height: 670px;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
  }
  @media (max-width: 414px) {
    padding-top: 13px;
  }
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 670px;
  @media (max-width: 600px) {
    height: 100%;
    align-items: center;
    text-align: center;
  }
`;

const BlockPicture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  width: 541px;
  height: 670px;
  @media (max-width: 834px) {
    height: 452px;
  }
  @media (max-width: 600px) {
    height: 100%;
  }
`;

const Image = styled.img`
  width: 541px;
  height: 670px;
  @media (max-width: 1200px) {
    width: 46vw;
    height: auto;
  }
  @media (max-width: 600px) {
    width: 90vw;
    height: auto;
  }
`;
