import requestToServer from './requestToServer';
import TempAgent from '../interfaces/TempAgent.interface';
import createTempAgentQuery from './queryBodies/createTempAgent';

export default async function handleCreateTempAgent(
  input: TempAgent,
  handleError?: Function
): Promise<Boolean> {
  return await requestToServer('mutation', createTempAgentQuery, {
    input,
  })!
    .then((result) => {
      if (result.error) {
        if (handleError) handleError(result.error);
        return false;
      }
      return true;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
}
