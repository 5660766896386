import { CardForMenu } from './interfaces/MenuCard.iterface';
import FaceIcon from '@material-ui/icons/Face';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import InfoIcon from '@material-ui/icons/Info';
import ekukoLinks from './utilites/ekukoLinks';
import intranetLinks from './utilites/intranetLinks';
import ekukoIntranetLinkForOffice from './utilites/ekukoIntranetLinkForOffice';

let userInfo = localStorage.getItem('user');
let officeId;

if (userInfo) {
  const userParse = JSON.parse(userInfo);
  officeId = userParse.branch_office_id;
}

let cardsData: CardForMenu[] = [
  {
    icon: () => <FaceIcon style={{ color: 'gray', fontSize: '50px' }} />,
    iconForHeader: () => (
      <FaceIcon
        style={{
          marginRight: '15px',
          fontSize: '40px',
          color: '#ffffff',
          cursor: 'pointer',
        }}
      />
    ),
    title: 'Homepage',
    description: 'Verwalten Sie Ihren Werbeauftritt',
    link: '/mypage/homepage',
  },
  {
    icon: () => (
      <LibraryAddCheckIcon style={{ color: 'gray', fontSize: '50px' }} />
    ),
    iconForHeader: () => (
      <LibraryAddCheckIcon
        style={{
          marginRight: '15px',
          fontSize: '40px',
          color: '#ffffff',
          cursor: 'pointer',
        }}
      />
    ),
    title: 'E/Kuko',
    description: 'Verwalten Sie Ihre Kunden',
    link: ekukoIntranetLinkForOffice({ officeId, listOfLinks: ekukoLinks }),
  },
  {
    icon: () => <InfoIcon style={{ color: 'gray', fontSize: '50px' }} />,
    iconForHeader: () => (
      <InfoIcon
        style={{
          marginRight: '15px',
          fontSize: '40px',
          color: '#ffffff',
          cursor: 'pointer',
        }}
      />
    ),
    title: 'Intranet',
    description: 'Infos zu deiner täglichen Arbeit',
    link: ekukoIntranetLinkForOffice({ officeId, listOfLinks: intranetLinks }),
  },
  {
    icon: () => <BeachAccessIcon style={{ color: 'gray', fontSize: '50px' }} />,
    iconForHeader: () => (
      <BeachAccessIcon
        style={{
          marginRight: '15px',
          fontSize: '40px',
          color: '#ffffff',
          cursor: 'pointer',
        }}
      />
    ),
    title: 'pepXpress',
    description: 'Deine Pep-Angebote',
    link: 'https://pepxpress.com/affiliate/mein-urlaubsgluck',
  },
  {
    icon: () => (
      <CastForEducationIcon style={{ color: 'gray', fontSize: '50px' }} />
    ),
    iconForHeader: () => (
      <CastForEducationIcon
        style={{
          marginRight: '15px',
          fontSize: '40px',
          color: '#ffffff',
          cursor: 'pointer',
        }}
      />
    ),
    title: 'MiMo-Plattform',
    description: 'Deine persönliche Lernplattform',
    link: 'https://myablefy.com/',
  },
  {
    icon: () => (
      <ShoppingCartIcon style={{ color: 'gray', fontSize: '50px' }} />
    ),
    iconForHeader: () => (
      <ShoppingCartIcon
        style={{
          marginRight: '15px',
          fontSize: '40px',
          color: '#ffffff',
          cursor: 'pointer',
        }}
      />
    ),
    title: 'E-Shop',
    description: 'Passwort: MUGLWebshop',
    link: 'https://mein-urlaubsglueck.shop/',
  },
];

if (officeId === 2 || officeId === 3) {
  cardsData = cardsData.filter(
    (card) => card.title !== 'MiMo-Plattform' && card.title !== 'E-Shop'
  );
}
if (officeId === 4) {
  cardsData = cardsData.filter((card) => card.title !== 'E-Shop');
}

export const cardsForUser: CardForMenu[] = cardsData;

export const cardsForAdminMenu: CardForMenu[] = [
  {
    icon: () => (
      <SupervisorAccountIcon style={{ color: 'gray', fontSize: '50px' }} />
    ),
    iconForHeader: () => (
      <SupervisorAccountIcon
        style={{
          marginRight: '15px',
          fontSize: '40px',
          color: '#ffffff',
          cursor: 'pointer',
        }}
      />
    ),
    title: 'Temporäre Agenten',
    description: 'Verwalten Sie Ihre Agenten',
    link: '/mypage/temp-agents',
  },
  {
    icon: () => <AssignmentIcon style={{ color: 'gray', fontSize: '50px' }} />,
    iconForHeader: () => (
      <AssignmentIcon
        style={{
          marginRight: '15px',
          fontSize: '40px',
          color: '#ffffff',
          cursor: 'pointer',
        }}
      />
    ),
    title: 'Berichte',
    description: 'Verwalten Sie die Berichte',
    link: '/mypage/moderation',
  },
  {
    icon: () => (
      <LibraryBooksIcon style={{ color: 'gray', fontSize: '50px' }} />
    ),
    iconForHeader: () => (
      <LibraryBooksIcon
        style={{
          marginRight: '15px',
          fontSize: '40px',
          color: '#ffffff',
          cursor: 'pointer',
        }}
      />
    ),
    title: 'Wiki',
    description: 'WIKIseiten',
    link:
      process.env.NODE_ENV === 'development'
        ? 'http://dev.rasum.net:4004/login'
        : `https://wiki.mein-urlaubsglueck.de/login`,
  },
  {
    icon: () => (
      <ShoppingCartIcon style={{ color: 'gray', fontSize: '50px' }} />
    ),
    iconForHeader: () => (
      <ShoppingCartIcon
        style={{
          marginRight: '15px',
          fontSize: '40px',
          color: '#ffffff',
          cursor: 'pointer',
        }}
      />
    ),
    title: 'E-Shop',
    description: '"Passwort: MUGLWebshop"',
    link: 'https://mein-urlaubsglueck.shop/',
  },
  {
    icon: () => <BeachAccessIcon style={{ color: 'gray', fontSize: '50px' }} />,
    iconForHeader: () => (
      <BeachAccessIcon
        style={{
          marginRight: '15px',
          fontSize: '40px',
          color: '#ffffff',
          cursor: 'pointer',
        }}
      />
    ),
    title: 'pepXpress',
    description: 'Deine Pep-Angebote',
    link: 'https://pepxpress.com/affiliate/mein-urlaubsgluck',
  },
  {
    icon: () => (
      <CastForEducationIcon style={{ color: 'gray', fontSize: '50px' }} />
    ),
    iconForHeader: () => (
      <CastForEducationIcon
        style={{
          marginRight: '15px',
          fontSize: '40px',
          color: '#ffffff',
          cursor: 'pointer',
        }}
      />
    ),
    title: 'MiMo-Plattform',
    description: 'Deine persönliche Lernplattform',
    link: 'https://myablefy.com/',
  },
];
