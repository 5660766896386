import { gql } from '@apollo/client';

const updateCustomer = gql`
  mutation ($input: inputCustomerData) {
    updateCustomer(input: $input) {
      _id
      name
      surname
      email
      salutation
      mailing
      phoneCalls
      digitalCommunication
      newsletter
      statusAccept
      dateAccept
      lastRequest
      notice
      is_male
    }
  }
`;

export default updateCustomer;
