import { createMuiTheme } from "@material-ui/core/styles";
import themeChoserForOffice from "./themeChoserForOffice";

let userInfo = localStorage.getItem("user");
let userInfoAfterParse = JSON.parse(userInfo);

const ThemeOption = createMuiTheme(
  themeChoserForOffice(userInfoAfterParse?.branch_office_id)
);

export default ThemeOption;
