import React, { ReactElement } from "react";
import { Box, IconButton } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useHistory } from "react-router-dom";
import { ButtonBack } from "../../interfaces/ButtonBack.interface";

export default function BackBtn({ link }: ButtonBack): ReactElement {
  let history = useHistory();
  return (
    <Box>
      <IconButton
        color="primary"
        onClick={() => {
          history.push(link);
        }}
      >
        <KeyboardBackspaceIcon />
      </IconButton>
    </Box>
  );
}
