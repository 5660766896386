const createORupdateStory = `mutation($input:inputStory, $id:String, $published_counterpart_id:String) {
  createORupdateStory(
    input: $input,
    id: $id,
    published_counterpart_id: $published_counterpart_id
)	{
  _id
  title
  text
  seo_description
  seo_keywords
  images
  themes{
    _id
    title
  }
  status
  updatedAt
  }
}`

export default createORupdateStory;