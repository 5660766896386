import jwt_decode from 'jwt-decode';
import { url } from './config/config';

function authUser(user: string, password: string) {
  const request = fetch(`${url}/login?username=${user}&password=${password}`)
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      localStorage.setItem('token', res.token);
      return true;
    })
    .catch((error) => {
      console.error('Login is error:', error);
      return false;
    });

  return request;
}

function userLogOut() {
  localStorage.removeItem('token');
  window.location.replace('/mypage/login');
}

interface User {
  createdAt: string;
  email: string;
  enabled: boolean;
  exp: number;
  iat: number;
  id: number;
  is_redactor: boolean;
  password_hash: string | null;
  updatedAt: string;
  username: string;
  __v: number;
  _id: string;
}

function getCurrentUser() {
  const userToken = localStorage.getItem('token');
  if (userToken) {
    try {
      const user: User = jwt_decode(userToken);
      if (user.exp > Math.floor(Date.now() / 1000)) {
        return user;
      }
      return false;
    } catch (error) {
      localStorage.removeItem('token');
      window.location.replace('/mypage/login');
      return false;
    }
  } else {
    return false;
  }
}

export { getCurrentUser, authUser, userLogOut };
