import { gql } from '@apollo/client';

const deleteCustomer = gql`
  mutation deleteCustomer($input: inputIdArray) {
    deleteCustomer(input: $input) {
      _id
      name
      surname
      email
      salutation
      mailing
      phoneCalls
      digitalCommunication
      newsletter
      statusAccept
      dateAccept
      lastRequest
      notice
    }
  }
`;
export default deleteCustomer;
