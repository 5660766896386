import * as Yup from 'yup';
import {
  CountryCode,
  parsePhoneNumber,
  ParseError,
  validatePhoneNumberLength,
} from 'libphonenumber-js';

const instaLinkValidation = Yup.string()
  .matches(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    'ist kein Link'
  )
  .matches(/(instagram)/, 'ist kein Instagram-Link')
  .nullable();
const facebookLinkValidation = Yup.string()
  .matches(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    'ist kein Link'
  )
  .matches(/(facebook)/, 'Kein Facebook-Link')
  .nullable();

function ValidationSchema(countryCode: string) {
  const schema = Yup.object().shape({
    phone: Yup.string()
      .min(2, 'min. 2 Zeichen')
      .test('uniq', 'zu Lang', (value) => {
        if (value) {
          let isValid = false;
          try {
            const isToLong = validatePhoneNumberLength(
              value,
              countryCode as CountryCode
            );
            isValid = !(isToLong === 'TOO_LONG');
          } catch (parseError) {
            if (parseError instanceof ParseError) {
              console.error(`status`, parseError.message);
            }
          }
          return isValid;
        }
        return true;
      })
      .test('uniq', 'Nummer ungültig', (value, context) => {
        if (value) {
          let isValid = false;
          try {
            const formatetPhoneNumber = parsePhoneNumber(
              value,
              countryCode as CountryCode
            );
            isValid = formatetPhoneNumber.isValid();
          } catch (parseError) {
            if (parseError instanceof ParseError) {
              console.error(`status`, parseError.message);
            }
          }
          return isValid;
        }
        return true;
      }),
    insta_link: instaLinkValidation,
    facebook_link: facebookLinkValidation,
  });
  return schema;
}

export default ValidationSchema;
