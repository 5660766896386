import React, { useState, useEffect } from "react";
import Header from "../components/blocks/Header";
import BrandPage from "../components/blocks/BrandPage";
import Typography from "@material-ui/core/Typography";
import ThemeOption from "../utilites/config/themeOption";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import SecondaryButton from "../components/UI/SecondaryButtonSmall";
import PrimaryButtonMedium from "../components/UI/PrimaryButtonMedium";
import ErrorButtonMedium from "../components/UI/ErrorButtonMedium";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
// import TourThemeSelect from "../components/blocks/TourThemeSelect";
// import TourThemeCheckboxSelect from "../components/blocks/TourThemeCheckboxSelect";
import getTourThemes from "../utilites/queryBodies/getTourThemes";
import getStoryById from "../utilites/queryBodies/getStoryById";
import getPublishedStorysById from "../utilites/queryBodies/getPublishedStorysById";
import createORupdateStory from "../utilites/queryBodies/createORupdateStory";
import setStoryToEXAMINATION from "../utilites/queryBodies/setStoryToEXAMINATION";
import setStoryToREJECT from "../utilites/queryBodies/setStoryToREJECT";
import setStoryToPUBLISHED from "../utilites/queryBodies/setStoryToPUBLISHED";
import uploadStoryImages from "../utilites/uploadStoryImages";
import requestToServer from "../utilites/requestToServer";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import { useHistory } from "react-router-dom";
import ImagesStory from "../components/blocks/ImagesStory";
import deleteStoryImage from "../utilites/queryBodies/deleteStoryImage";
import { minImagesStory } from "../utilites/config/config";
import ReactSelect from "../../src/components/UI/ReactSelect";
import LoadingOverlay from "../components/blocks/LoadingOverlay.tsx";
import ButtonBack from "../components/UI/ButtonBack";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "block",
    maxWidth: "1200px",
    margin: "40px auto",
    boxSizing: "border-box",
  },
  icon: {
    display: "block",
    fontSize: "32px",
    cursor: "pointer",
    color: "#ffffff",
    "&:hover": { color: "#54BFD0" },
  },
  ItemBox: {
    display: "flex",
    margin: "10px",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  RowBox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  Input: {
    width: "100%",
  },
  caption: {
    color: "#333333",
    fontSize: "28px",
    lineHeight: "32px",
    fontFamily: "'Poppins', sans-serif",
  },
  checkBox: {
    display: "block",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "16px",
    lineHeight: "24px",
  },
  formControl: {
    width: "100%",
  },
  coverBox: {
    display: "block",
    width: "100%",
    height: "314px",
    margin: "10px 0",
  },
  imageBox: {
    display: "block",
    maxWidth: "184px",
    height: "174px",
    margin: "10px 5px",
  },
  InputMultiline: {
    width: "100%",
  },
  buttonBack: {
    position: "absolute",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export default function EditArticlePage({
  idArticle,
  isRedactor,
  isPublished,
}) {
  let history = useHistory();
  const classes = useStyles();
  const [article, setArticle] = useState({
    _id: "",
    title: "",
    text: "",
    seo_description: "",
    seo_keywords: [],
    themes: [
      {
        _id: "",
        title: "",
      },
    ],
    images: [],
    status: "",
    updatedAt: "",
    reject_reason: "",
  });
  const [tourTheme, setTourTheme] = useState(null);
  const [listTourThemes, setListTourThemes] = useState([
    {
      value: "1",
      label: "Title tour theme",
    },
  ]);

  const [currentStoryImage, setCurrentStoryImage] = useState([]);
  const [requestStoryImage, setRequestStoryImage] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getArrayThourTheme = async () => {
      console.info("getArrayThourTheme");
      requestToServer("query", getTourThemes, {})
        .then((result) => {
          console.info("Response", result);
          const list = result.getTourThemes.map((item) => {
            return { value: item._id, label: item.title };
          });
          return list;
        })
        .then((list) => {
          console.info("Response", list);
          setListTourThemes(list);
          if (!idArticle) setIsLoading(false);
        })
        .catch((error) => {
          console.info(
            "There is requestToServer data Error in this AllParametrs request:",
            error
          );
          return [];
        });
    };
    getArrayThourTheme();

    if (idArticle) {
      async function getData() {
        if (isPublished) {
          requestToServer("query", getPublishedStorysById(idArticle), {})
            .then((result) => {
              setArticle(result.getPublishedStorysById);
              setCurrentStoryImage(result.getPublishedStorysById.images);
              const list = result.getPublishedStorysById.themes.map((item) => {
                return { value: item._id, label: item.title };
              });
              return list;
            })
            .then((list) => {
              setTourTheme(list);
              setIsLoading(false);
            })
            .catch((error) => {
              console.log(
                "There is requestToServer data Error in this AllParametrs request:",
                error
              );
              return [];
            });
        } else {
          requestToServer("query", getStoryById(idArticle), {})
            .then((result) => {
              setArticle(result.getStoryById);
              setCurrentStoryImage(result.getStoryById.images);
              const list = result.getStoryById.themes.map((item) => {
                return { value: item._id, label: item.title };
              });
              return list;
            })
            .then((list) => {
              setTourTheme(list);
              setIsLoading(false);
            })
            .catch((error) => {
              console.log(
                "There is requestToServer data Error in this AllParametrs request:",
                error
              );
              return [];
            });
        }
      }
      getData();
    }
  }, [idArticle, isPublished]);

  const changeArticle = (event) => {
    if (event.target.name === "text") {
      let currentText = event.target.value;
      currentText = currentText
        .split("\n\n")
        .map(function (item) {
          return "<p>" + item + "</p>";
        })
        .join("")
        .replace(/\n/g, "<br>");

      setArticle({
        ...article,
        [event.target.name]: currentText,
      });
    }
    setArticle({
      ...article,
      [event.target.name]: event.target.value,
    });
    checkFields();
  };

  const checkFields = () => {
    if (
      article.title.length > 4 &&
      article.title.length < 61 &&
      article.seo_description.length > 4 &&
      article.seo_description.length < 97
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const createORupdateStoryHandler = (article) => {
    setIsDisabled(true);
    setIsLoading(true);
    const arrayIdTourTheme = tourTheme.map((item) => item.value);
    const input = {
      title: article.title,
      text: article.text,
      seo_description: article.seo_description,
      seo_keywords: article.seo_keywords,
      themes: arrayIdTourTheme,
    };
    const id = idArticle ? idArticle.toString() : "";

    requestToServer("mutation", createORupdateStory, { input, id })
      .then((result) => {
        setArticle(result.createORupdateStory);
        handlerUploadImagesStory(result.createORupdateStory._id);
        setIsLoading(false);
        setIsDisabled(false);
      })
      .catch((error) => {
        console.log(
          "There is requestToServer data Error in this AllParametrs request:",
          error
        );
        return [];
      });
  };

  const handlerUploadImagesStory = (id) => {
    if (requestStoryImage.length > 0) {
      let count = 0;
      requestStoryImage.map(async (image) => {
        try {
          const responseImagesStory = await uploadStoryImages(id, image);

          count = count + 1;
          if (count === requestStoryImage.length) {
            history.push("/mypage/edit-article/" + id);
          }
        } catch (err) {
          console.log("responseImagesStory", err);
        }
      });
    }
  };

  const handleSetStoryToEXAMINATION = () => {
    const id = idArticle ? idArticle.toString() : "";

    requestToServer("mutation", setStoryToEXAMINATION, { id })
      .then((result) => {
        history.push("/mypage");
      })
      .catch((error) => {
        console.log(
          "There is requestToServer data Error in this AllParametrs request:",
          error
        );
        return [];
      });
  };

  const handleSetStoryToREJECT = () => {
    const id = idArticle ? idArticle.toString() : "";
    const reject_reason = article.reject_reason;

    requestToServer("mutation", setStoryToREJECT, { id, reject_reason })
      .then((result) => {
        // console.log("Response", result);
        // history.push("/");
      })
      .catch((error) => {
        console.log(
          "There is requestToServer data Error in this AllParametrs request:",
          error
        );
        return [];
      });
  };

  const handleSetStoryToPUBLISHED = () => {
    const id = idArticle ? idArticle.toString() : "";
    const input = {
      title: article.title,
      text: article.text,
      seo_description: article.seo_description,
    };

    requestToServer("mutation", setStoryToPUBLISHED, { id, input })
      .then((result) => {
        history.push("/mypage");
      })
      .catch((error) => {
        console.log(
          "There is requestToServer data Error in this AllParametrs request:",
          error
        );
        return [];
      });
  };

  function changeCurrenImageStory(event) {
    let file = event.target.files[0];
    if (event.target.files[0].size < 10000000) {
      if (
        file.name.includes(".png") ||
        file.name.includes(".jpg") ||
        file.name.includes(".jpeg")
      ) {
        const arrayCurrentStoryImage = [...currentStoryImage];
        arrayCurrentStoryImage.push(URL.createObjectURL(file));
        setCurrentStoryImage(arrayCurrentStoryImage);

        const arrayRequestStoryImage = [...requestStoryImage];
        arrayRequestStoryImage.push(file);
        setRequestStoryImage(arrayRequestStoryImage);
      } else {
        alert("Es kann nur .jpg .png .jpeg verwendet werden");
      }
    } else {
      alert("Das Bild ist zu groß");
    }
  }

  const handleDeleteImage = (index, fileName) => {
    const arrayCurrentStoryImage = [...currentStoryImage];
    arrayCurrentStoryImage.splice(index, 1);
    setCurrentStoryImage(arrayCurrentStoryImage);

    const arrayRequestStoryImage = [...requestStoryImage];
    arrayRequestStoryImage.splice(index, 1);
    setRequestStoryImage(arrayRequestStoryImage);

    const id = idArticle ? idArticle.toString() : "";

    requestToServer("mutation", deleteStoryImage, { id, fileName })
      .then((result) => {
        console.log("Response deleteStoryImage", result);
      })
      .catch((error) => {
        console.log(
          "There is requestToServer data Error in this AllParametrs request:",
          error
        );
        return [];
      });
  };

  return (
    <ThemeProvider theme={ThemeOption}>
      <LoadingOverlay show={isLoading} />
      <Header isRedactor={isRedactor} />
      <Box className={classes.content}>
        <BrandPage pageName="Berichte" />
        <ButtonBack
          link={isRedactor ? "/mypage/moderation" : "/mypage/homepage"}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box className={classes.RowBox}>
              <Box className={classes.coverBox}>
                <ImagesStory
                  isRedactor={isRedactor}
                  images={currentStoryImage}
                  changeCurrenImageStory={changeCurrenImageStory}
                  handleDeleteImage={handleDeleteImage}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box className={classes.ItemBox}>
              <Typography className={classes.caption} component="span">
                Hinzufügen eines neuen Artikels
              </Typography>
            </Box>
            {(article.reject_reason || isRedactor) && (
              <Box className={classes.ItemBox}>
                <TextField
                  className={classes.Input}
                  id="Titelleiste"
                  variant="filled"
                  label="Ablehnungsgrund"
                  value={article.reject_reason}
                  onChange={(event) => {
                    changeArticle(event);
                  }}
                  name="reject_reason"
                  multiline
                  rows={5}
                  error={!Boolean(article.reject_reason)}
                  disabled={!isRedactor}
                />
              </Box>
            )}
            <Box className={classes.ItemBox}>
              <ReactSelect
                value={tourTheme}
                listValue={listTourThemes}
                changeData={setTourTheme}
                isMulti={true}
                disabled={isRedactor}
                error={!Boolean(tourTheme && tourTheme.length > 0)}
                label="Meine Themen"
                helperText="Obligatorisches Feld"
              />
            </Box>
            <Box className={classes.ItemBox}>
              <TextField
                className={classes.Input}
                id="Titelleiste"
                variant="filled"
                label="Titelleiste"
                value={article.title}
                onChange={(event) => {
                  changeArticle(event);
                }}
                name="title"
                error={
                  !Boolean(
                    article.title.length > 4 && article.title.length < 61
                  )
                }
                helperText="min 5 max 60"
              />
            </Box>
            <Box className={classes.ItemBox}>
              <TextField
                className={classes.Input}
                id="IntroText"
                variant="filled"
                label="IntroText"
                value={article.seo_description}
                onChange={(event) => {
                  changeArticle(event);
                }}
                name="seo_description"
                error={
                  !Boolean(
                    article.seo_description.length > 4 &&
                      article.seo_description.length < 97
                  )
                }
                helperText="min 5 max 96"
              />
            </Box>
            <Box className={classes.ItemBox}>
              <TextField
                id="filled-multiline-flexible"
                className={classes.InputMultiline}
                label="Text"
                multiline
                rows={15}
                variant="filled"
                value={article.text}
                onChange={(event) => {
                  changeArticle(event);
                }}
                name="text"
                error={!Boolean(article.text)}
                helperText="Obligatorisches Feld"
              />
            </Box>
            <Box className={classes.ItemBox}>
              {!isRedactor && (
                <PrimaryButtonMedium
                  onClick={() => {
                    createORupdateStoryHandler(article);
                  }}
                  disabled={
                    currentStoryImage.length < +minImagesStory || isDisabled
                  }
                >
                  Änderung zwischenspeichern
                </PrimaryButtonMedium>
              )}
              {!(article.status === "PUBLISHED") && (
                <div>
                  {isRedactor && (
                    <PrimaryButtonMedium
                      onClick={() => {
                        handleSetStoryToPUBLISHED();
                      }}
                      disabled={Boolean(article.reject_reason)}
                    >
                      <ThumbUpOutlinedIcon /> &#160; Artikel genehmigen
                    </PrimaryButtonMedium>
                  )}
                </div>
              )}
            </Box>
            <Box className={classes.ItemBox}>
              {article._id && (
                <div>
                  {!isRedactor && (
                    <SecondaryButton
                      onClick={() => {
                        handleSetStoryToEXAMINATION();
                      }}
                    >
                      zur Freigabe abschicken
                    </SecondaryButton>
                  )}
                </div>
              )}
            </Box>
            <Box className={classes.ItemBox}>
              {isRedactor && (
                <ErrorButtonMedium
                  onClick={() => {
                    handleSetStoryToREJECT();
                  }}
                  disabled={!Boolean(article.reject_reason)}
                >
                  <ThumbDownOutlinedIcon /> &#160; Artikel ablehnen
                </ErrorButtonMedium>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
