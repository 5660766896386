import React from 'react';
import styled from 'styled-components';
import PictureCloud from '../static/images/CloudBG.png';
import ButtonTextOnly from '../components/UI/ButtonTextOnly';

//TODO: Der Button verfügt über keine Funktionalität. Muss Besprochen werden

export default function ErrorPage() {
  return (
    <Block>
      <TextBox>
        <Text>404</Text>
      </TextBox>
      <BlockPicture>
        <ContentBlock>
          <Heading1 color='#428DCB'>Seite nicht gefunden, </Heading1>
          <br />
          <Heading2 color='#B9B9B9'>
            Bitte überprüfen Sie die korrekte Schreibweise der URL
          </Heading2>
          <br />
          <ButtonLargeBox>
            <ButtonTextOnly size='large'>Zurück zur Hauptseite</ButtonTextOnly>
          </ButtonLargeBox>
          <ButtonMediumBox>
            <ButtonTextOnly size='medium'>Zurück zur Hauptseite</ButtonTextOnly>
          </ButtonMediumBox>
          <ButtonSmallBox>
            <ButtonTextOnly size='small'>Zurück zur Hauptseite</ButtonTextOnly>
          </ButtonSmallBox>
        </ContentBlock>
      </BlockPicture>
    </Block>
  );
}

const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 1024px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #e4ebf1 0%, #428dcb 63.69%);
  @media (max-width: 570px) {
    height: 812px;
  } ;
`;

const TextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 180px;
  padding-top: 130px;
  @media (max-width: 570px) {
    padding-top: 31px;
  } ;
`;

const Text = styled.span`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 200px;
  line-height: 180px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 6px 0px #bfbfbf;
  @media (max-width: 570px) {
    font-size: 118px;
    line-height: 140px;
  } ;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 20vh;
  box-sizing: border-box;
  @media (max-width: 834px) {
    height: 150px;
    padding-top: 40px;
  }
  @media (max-width: 414px) {
    height: 206px;
    padding-top: 20px;
  } ;
`;

const Heading1 = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 70px;
  line-height: 77px;
  margin: 0;
  color: ${(props) => {
    if (props.color) {
      return props.color;
    } else {
      return '#383838';
    }
  }};
  @media (max-width: 834px) {
    font-size: 36px;
    line-height: 36px;
  }
  @media (max-width: 414px) {
    font-size: 28px;
    line-height: 32px;
  } ;
`;

const Heading2 = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  margin: 0;
  color: ${(props) => {
    if (props.color) {
      return props.color;
    } else {
      return '#383838';
    }
  }};
  @media (max-width: 834px) {
    font-size: 28px;
    line-height: 32px;
  } ;
`;

const BlockPicture = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: calc(1024px - 512px);
  background-image: url(${PictureCloud});
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 512px;
  @media (max-width: 834px) {
    top: calc(1024px - 443px);
    height: 443px;
  }
  @media (max-width: 570px) {
    top: calc(812px - 320px);
    height: 320px;
  }
  @media (max-width: 414px) {
    top: calc(812px - 300px);
    height: 300px;
  } ;
`;

const ButtonLargeBox = styled.div`
  display: flex;
  padding-top: 40px;
  @media (max-width: 834px) {
    padding-top: 10px;
    display: none;
  }
  @media (max-width: 414px) {
    display: none;
  } ;
`;

const ButtonMediumBox = styled.div`
  display: none;
  @media (max-width: 834px) {
    display: flex;
    padding-top: 10px;
  }
  @media (max-width: 414px) {
    display: none;
  } ;
`;

const ButtonSmallBox = styled.div`
  display: none;
  @media (max-width: 834px) {
    display: none;
  }
  @media (max-width: 414px) {
    display: flex;
    padding-top: 10px;
  } ;
`;
