import React from 'react';
import styled from 'styled-components';

interface props {
  readonly color: string;
}

const HeadingSix = styled.h6<props>`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => {
    if (props.color) {
      return props.color;
    } else {
      return '#383838';
    }
  }};
  margin: 0;
`;
interface input {
  children: any;
  color?: string;
}
export default function Heading6({ children, color = '' }: input) {
  return <HeadingSix color={color}>{children}</HeadingSix>;
}
