import { Site } from '../Site.types';

const siteInitValue: Site = {
  aboutMe: '',
  agent: [{ name: '', surname: '', _id: '' }],
  agent_image: '',
  facebook_link: '',
  insta_link: '',
  isWhatsApp: false,
  is_aktive: false,
  phone: '',
  route: '',
  seo_description: '',
  stroys: [],
  tourTheme: [],
  _id: '',
};
export default siteInitValue;
