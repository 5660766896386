import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  display: inline-block;
  > input {
    opacity: 0;
  }
  > input + label {
    position: relative;
    padding-left: 33px;
    padding-top: 2px;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 1px;
      width: 18px;
      height: 18px;
      border: 2px solid #666666;
      background: #ffffff;
      border-radius: 2px;
    }
    &:after {
      content: '✔';
      position: absolute;
      top: 1px;
      left: 4px;
      font-size: 16px;
      color: #ffffff;
      transition: all 0.2s;
    }
  }
  > input:not(:checked) + label {
    &:after {
      opacity: 0;
      transform: scale(0);
    }
  }
  > input:disabled:not(:checked) + label {
    &:before {
      box-shadow: none;
      border-color: #bbb;
      background-color: #ddd;
    }
  }
  > input:checked + label {
    &:after {
      opacity: 1;
      transform: scale(1);
    }
    &:before {
      background: #428dcb;
      border: 2px solid #428dcb;
    }
  }
  > input:disabled:checked + label {
    &:after {
      color: #999;
    }
  }
  > input:disabled + label {
    color: #aaa;
  }
  > input:checked:focus + label,
  input:not(:checked):focus + label {
    &:before {
      border: 2px dotted #428dcb;
    }
  }
`;

const Label = styled.label`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => {
    if (props.color) {
      return props.color;
    } else {
      return '#666666';
    }
  }};
`;

export default function CheckBox({ label = 'Mail', initValue, getValue }) {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(initValue);
  }, []);
  useEffect(() => {
    getValue(value);
  }, [value]);

  return (
    <Styled onClick={() => setValue(!value)}>
      <input type='checkbox' checked={value} />
      <Label>{label}</Label>
    </Styled>
  );
}
