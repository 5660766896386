import React, { useState } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import ThemeOption from '../utilites/config/themeOption';
import { Box, Grid, Switch } from '@material-ui/core';
import Header from '../components/blocks/Header';
import BrandPage from '../components/blocks/BrandPage';
import ButtonBack from '../components/UI/ButtonBack';
import { Search as SearchIcon } from '@styled-icons/boxicons-regular/Search';
import InputFilled from '../components/UI/InputFilled';
import ImportModal from '../components/blocks/ImportModal';
import ButtonIconLeft from '../components/UI/ButtonIconLeft';
import Label from '../components/UI/Typography/Label';
import getUserQuery from '../utilites/Apollo/query/getUserQuery';
import ExpoertXLSX from '../components/blocks/ExportXLSX';
import CustomerList from '../components/blocks/CustomerList';
import EditCustomer from '../components/blocks/EditCusomer';

function reverseArr(input) {
  let ret = [];
  for (let i = input.length - 1; i >= 0; i--) {
    ret.push(input[i]);
  }
  return ret;
}

const filterArray = (customerList, confirmed, unconfirmed, searchFor = '') => {
  if (!customerList) return [];
  if (confirmed) {
    return customerList.filter((customer) => customer.statusAccept);
  } else if (unconfirmed) {
    return customerList.filter((customer) => !customer.statusAccept);
  } else if (searchFor) {
    return customerList.filter(
      (customer) =>
        customer.name?.toLowerCase().includes(searchFor) ||
        customer.surname?.toLowerCase().includes(searchFor) ||
        (customer.name + ' ' + customer.surname)
          .toLowerCase()
          .includes(searchFor)
    );
  } else {
    return reverseArr(customerList);
  }
};

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'block',
    maxWidth: '1200px',
    margin: '40px auto',
    boxSizing: 'border-box',
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '1144px',
    height: '82px',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    margin: '40px auto',
    boxSizing: 'border-box',
  },
  middleSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5%',
    width: '100%',
    height: '100vh',
    margin: '40px auto',
    boxSizing: 'border-box',
  },
}));

function EkukoPage(isRedactor) {
  const [allSwitch, setAllSwitch] = useState(true);
  const [confirmedSwitch, setConfirmedSwitch] = useState(false);
  const [unconfirmedSwitch, setUnconfirmedSwitch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState({});

  const { loading, data, refetch } = useQuery(getUserQuery, {
    fetchPolicy: 'no-cache',
  });

  const resetCustomer = () => {
    setSelectedCustomer({});
  };

  const classes = useStyles();
  return (
    <ThemeProvider theme={ThemeOption}>
      <Header isRedactor={isRedactor.isRedactor} />
      <Box className={classes.mainContainer}>
        <Box className={classes.content}>
          <BrandPage pageName='Ekuko' />
          <ButtonBack link='/mypage' />
          <Box className={classes.menu}>
            <Box width={360}>
              <InputFilled
                iconRight={<SearchIcon size={20} />}
                label='Suchen'
                placeholder=''
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
              />
            </Box>
            <ImportModal refetch={refetch} />
            <ExpoertXLSX
              csvData={data?.getUser?.customers}
              fileName={
                'ekuko_kunden_' + new Date().toISOString().split('.')[0]
              }
            />
            <ButtonIconLeft
              style={{
                borderRadius: ThemeOption.btn.borderRadius,
                color: ThemeOption.typography.textcolor,
              }}
              size='small'
              onClick={() => {
                setSelectedCustomer({});
              }}
            >
              Kunden hinzufügen
            </ButtonIconLeft>
          </Box>
          <Box marginTop={'40px'} display={'flex'}>
            <Box>
              <Label>Alle</Label>
              <Switch
                checked={allSwitch}
                onChange={() => {
                  setAllSwitch(!allSwitch);
                  setConfirmedSwitch(false);
                  setUnconfirmedSwitch(false);
                }}
                disabled={allSwitch}
                color='primary'
                name='checkedB'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Box>
            <Box>
              <Label>Bestätigt</Label>
              <Switch
                checked={confirmedSwitch}
                onChange={() => {
                  setAllSwitch(false);
                  setConfirmedSwitch(!confirmedSwitch);
                  setUnconfirmedSwitch(false);
                }}
                disabled={confirmedSwitch}
                color='primary'
                name='checkedB'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Box>
            <Box>
              <Label>Unbestätigt</Label>
              <Switch
                checked={unconfirmedSwitch}
                onChange={() => {
                  setAllSwitch(false);
                  setConfirmedSwitch(false);
                  setUnconfirmedSwitch(!unconfirmedSwitch);
                }}
                disabled={unconfirmedSwitch}
                color='primary'
                name='checkedB'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Box>
          </Box>
          <Box className={classes.middleSection}>
            <CustomerList
              isLoading={loading}
              list={filterArray(
                data?.getUser?.customers,
                confirmedSwitch,
                unconfirmedSwitch,
                searchInput.toLowerCase()
              )}
              onSelectedItem={setSelectedCustomer}
            />
            <Box style={{ width: '570px' }}>
              <EditCustomer
                triggerReload={refetch}
                customer={selectedCustomer}
                setCustomer={setSelectedCustomer}
                resetCustomer={resetCustomer}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default EkukoPage;
