import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../components/blocks/Header";
import BrandPage from "../components/blocks/BrandPage";
import Typography from "@material-ui/core/Typography";
import ThemeOption from "../utilites/config/themeOption";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import ArticleCard from "../components/blocks/ArticleCard";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import getExaminationStorys from "../utilites/queryBodies/getExaminationStorys";
import getPublishedStorys from "../utilites/queryBodies/getPublishedStorys";
import requestToServer from "../utilites/requestToServer";
import LoadingOverlay from "../components/blocks/LoadingOverlay";
import SecondaryButtonMedium from "../components/UI/SecondaryButtonMedium";
import ButtonBack from "../components/UI/ButtonBack";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "block",
    maxWidth: "1200px",
    margin: "40px auto",
    boxSizing: "border-box",
  },
  icon: {
    display: "block",
    fontSize: "32px",
    cursor: "pointer",
    color: "#ffffff",
    "&:hover": { color: "#54BFD0" },
  },
  ItemBox: {
    display: "block",
    margin: "10px",
  },
  RowBox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginBottom: "10px",
    },
  },
  TabBox: {
    marginTop: "50px",
  },
  Input: {
    width: "100%",
  },
  caption: {
    color: "#333333",
    fontSize: "28px",
    lineHeight: "32px",
    fontFamily: "'Poppins', sans-serif",
  },
  tabCaption: {
    color: "#333333",
    fontSize: "18px",
    lineHeight: "24px",
    fontFamily: "'Poppins', sans-serif",
  },
  checkBox: {
    display: "block",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "16px",
    lineHeight: "24px",
  },
  formControl: {
    width: "100%",
  },
}));

export default function ArticlesListPageModeration({ isRedactor }) {
  let history = useHistory();
  const classes = useStyles();
  const [listArticlesPublished, setListArticlesPublished] = useState([]);
  const [listArticlesExamination, setListArticlesExamination] = useState([]);
  const [isShowPublishedStorys, setIsShowPublishedStorys] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleChangeIsShowPublished = () => {
    setIsShowPublishedStorys(!isShowPublishedStorys);
  };

  useEffect(() => {
    async function getData() {
      const arrayRequest = [getExaminationStorys, getPublishedStorys];
      requestToServer("query", arrayRequest, {})
        .then((result) => {
          setListArticlesExamination(result.getExaminationStorys);
          setListArticlesPublished(result.getPublishedStorys);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(
            "There is requestToServer data Error in this AllParametrs request:",
            error
          );
          return [];
        });
    }
    getData();
  }, []);

  return (
    <ThemeProvider theme={ThemeOption}>
      <LoadingOverlay show={isLoading} />
      <Header isRedactor={isRedactor} />
      <Box className={classes.content}>
        <BrandPage pageName="Berichte" />
        <ButtonBack link="/mypage" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.RowBox}>
              <Typography className={classes.caption} component="span">
                Artikel zur Prüfung
              </Typography>
            </Box>
            <Box className={classes.TabBox}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isShowPublishedStorys}
                    onChange={handleChangeIsShowPublished}
                    color="primary"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label="Veröffentlicht"
              />
              {!isShowPublishedStorys && (
                <div>
                  {listArticlesExamination &&
                    listArticlesExamination.map((item) => {
                      return (
                        <Box key={item._id} className={classes.ItemBox}>
                          <ArticleCard
                            agent={item.agent}
                            id={item._id}
                            data={item.data}
                            coverUrl={item.images[0]}
                            title={item.title}
                            status={item.status}
                            isRedactor={isRedactor}
                            modURL="/mypage/edit-article/"
                          />
                        </Box>
                      );
                    })}
                </div>
              )}

              {isShowPublishedStorys && (
                <div>
                  {listArticlesPublished &&
                    listArticlesPublished.map((item) => {
                      return (
                        <Box key={item._id} className={classes.ItemBox}>
                          <ArticleCard
                            agent={item.agent}
                            id={item._id}
                            data={item.data}
                            coverUrl={item.images[0]}
                            title={item.title}
                            status={item.status}
                            isRedactor={isRedactor}
                            isActive={item.is_aktive}
                            modURL="/mypage/edit-published-article/"
                          />
                        </Box>
                      );
                    })}
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
