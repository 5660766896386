import requestToServer from './requestToServer';
import setSiteToDisableQuery from './queryBodies/setSiteToDisable';

export default function setSiteToAktive(id: string): Promise<Boolean> {
  return requestToServer('mutation', setSiteToDisableQuery, { id })!
    .then((result) => {
      console.log();
      if (result.error) {
        console.error(result.error);
        return false;
      }
      return true;
    })
    .catch((err) => {
      return false;
    });
}
