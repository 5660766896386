import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function ListTourTheme({
  listTourThemes = [
    {
      _id: "1",
      title: "Title tour theme 1",
      subtitle: "Subtitle tour theme",
      description: "Description tour theme",
      image: "",
    },
    {
      _id: "2",
      title: "Title tour theme 2",
      subtitle: "Subtitle tour theme",
      description: "Description tour theme",
      image: "",
    },
  ],
  tourTheme = [{
    _id: "2",
    title: "Title tour theme 2",
  }],
  changeTourTheme,
}) {

    const handleChange = (value, id, title) => {
        console.log("Tourtheme", value, id, title)
        let currentToursTheme = [...tourTheme];
        const currentItem = {_id: id, title: title};
        if(value) {
            currentToursTheme.push(currentItem);
        } else {
            currentToursTheme = currentToursTheme.filter(item => item._id !== id)
        }
         console.log("currentToursTheme", currentToursTheme)
         changeTourTheme(currentToursTheme.filter(item=>item));
    }

  return (
    <div>
      {listTourThemes.map((item) => {
          let checked = tourTheme.some(elem => item._id === elem._id)
        return (
          <div key={item._id}>
            <FormControlLabel
              control={<Checkbox  
                checked={checked} 
                onChange={(event)=>{handleChange(event.target.checked, item._id, item.title)}} 
                color="primary" 
            />}
              label={item.title}
            />
          </div>
        );
      })}
    </div>
  );
}
