import React from 'react';
import styled from 'styled-components';

const InputFilledBlock = styled.input`
  padding: 16px 12px 16px 8px;
  width: 100%;
  height: 55px;
  background: #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  border: none;
  border-bottom: ${(props) => {
    if (props.isError) {
      return 'solid 1px #D93D23;';
    } else {
      return 'solid 1px #666666;';
    }
  }};
  outline: none;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  padding-top: 30px;
  padding-right: ${(props) => {
    if (props.isIconRight) {
      return '30px';
    } else {
      return '0px';
    }
  }};
  padding-left: ${(props) => {
    if (props.isIconLeft) {
      return '30px';
    } else {
      return '0px';
    }
  }};

  ::placeholder {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }

  &:hover {
    background: #dadada;
    transition: all 0.3s ease-out;
    border-bottom: 1px solid #262626;
  }

  &:focus {
    background: #f2f2f2;
    transition: all 0.3s ease-out;
    color: #262626;
    border-bottom: ${(props) => {
      if (props.isError) {
        return 'solid 1px #D93D23;';
      } else {
        return '1px solid #316A98;';
      }
    }};
  }

  &:active {
    background: #f2f2f2;
    transition: all 0.3s ease-out;
    border-bottom: 1px solid #262626;
    box-sizing: border-box;
    color: #262626;
  }

  &:disabled {
    background: #f2f2f2;
    transition: all 0.3s ease-out;
    cursor: default;
    border-bottom: 1px solid #b9b9b9;
    ::placeholder {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      color: #b9b9b9;
    }
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const LabelBox = styled.div`
  position: absolute;
  top: 1px;
  padding-left: ${(props) => {
    if (props.isIconLeft) {
      return '30px';
    } else {
      return '0px';
    }
  }};
`;

const Label = styled.span`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: ${(props) => {
    if (props.isError) {
      return '#E32144';
    } else if (props.isDisabled) {
      return '#B9B9B9';
    } else {
      return '#666666';
    }
  }};
`;

const IconLeftBox = styled.div`
  position: absolute;
  top: 20px;
  color: ${(props) => {
    if (props.isDisabled) {
      return '#B9B9B9';
    } else {
      return '#666666';
    }
  }};
`;

const IconRightBox = styled.div`
  position: absolute;
  top: 20px;
  right: 0px;
  color: ${(props) => {
    if (props.isDisabled) {
      return '#B9B9B9';
    } else {
      return '#666666';
    }
  }};
`;

const HelperTextBox = styled.div`
  width: 100%;
  height: 18px;
  padding-left: 8px;
  padding-top: 8px;
  font-family: 'Poppins', sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
  color: ${(props) => {
    if (props.isDisabled) {
      return '#B9B9B9';
    } else {
      return '#666666';
    }
  }};
`;

const ErrorTextBox = styled.div`
  width: 100%;
  height: 18px;
  padding-left: 8px;
  padding-top: 8px;
  font-family: 'Poppins', sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
  color: #e32144;
`;

export default function InputFilled({
  onChange,
  value,
  isDisabled = false,
  placeholder = 'Typing text',
  label = 'Label Text',
  type = 'text',
  iconLeft = '',
  iconRight = '',
  helperText = '',
  errorText = '',
}) {
  return (
    <Box>
      <LabelBox isDisabled={isDisabled} isIconLeft={Boolean(iconLeft)}>
        <Label isError={Boolean(errorText)} isDisabled={isDisabled}>
          {label}
        </Label>
      </LabelBox>
      {Boolean(iconLeft) && (
        <IconLeftBox isDisabled={isDisabled}>{iconLeft}</IconLeftBox>
      )}
      {Boolean(iconRight) && (
        <IconRightBox isDisabled={isDisabled}>{iconRight}</IconRightBox>
      )}
      <InputFilledBlock
        isError={Boolean(errorText)}
        onChange={onChange}
        disabled={isDisabled}
        isIconLeft={Boolean(iconLeft)}
        isIconRight={Boolean(iconRight)}
        value={value}
        type={type}
        placeholder={placeholder}
      />
      {!Boolean(errorText) && (
        <>
          {Boolean(helperText) && (
            <HelperTextBox isDisabled={isDisabled}>{helperText}</HelperTextBox>
          )}
        </>
      )}
      {Boolean(errorText) && <ErrorTextBox>{errorText}</ErrorTextBox>}
    </Box>
  );
}
