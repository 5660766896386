import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import DeleteDialog from '../DeleteDialog';
import PrimaryButtonMedium from '../../UI/PrimaryButtonMedium';
import UserAvatar from '../../blocks/UserAvatar';
import ListTourTheme from '../../blocks/ListTourTheme';
import requestToServer from '../../../utilites/requestToServer';
import getTourThemes from '../../../utilites/queryBodies/getTourThemes';
import getAdvertisingTheme from '../../../utilites/queryBodies/getAdvertisingTheme';
import LoadingOverlay from '../../blocks/LoadingOverlay';
import getSiteByID from '../../../utilites/queryBodies/getSiteByAgentID';
import TempAgent from '../../../interfaces/TempAgent.interface';
import TourThema from '../../../interfaces/TourThema.interface';
import inputTempAgent from '../../../interfaces/inputTempAgent.interface';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import setSiteToAktive from '../../../utilites/setSiteToAktive';
import setSiteToDisable from '../../../utilites/setSiteToDisable';
import deleteExternAgent from '../../../utilites/deleteExternAgent';
import SelectUI from '../../UI/SelectUI';
import handleCreateTempAgent from '../../../utilites/handleCreateTempAgent';
import handleUpdateTempAgent from '../../../utilites/handleUpdateTempAgent';
import handleUpdateSite from '../../../utilites/handleUpdateSite';

const useStyles = makeStyles((theme) => ({
  caption: {
    color: '#333333',
    fontSize: '28px',
    lineHeight: '32px',
    fontFamily: "'Poppins', sans-serif",
  },
  RowBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginBottom: '10px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '570px',
  },
  input: {
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '32px',
  },
  ItemBox: {
    width: '100%',
    display: 'block',
    margin: '20px 10px',
  },
}));

function handleSiteStatus(id: string, status: boolean): Promise<Boolean> {
  if (status) {
    return setSiteToDisable(id);
  } else {
    return setSiteToAktive(id);
  }
}

function handleErrors(
  error: Array<any>,
  errorState: Array<any>,
  SetError: Function
): void {
  if (!error || error.length === 0) return;
  const tempErrorArray = errorState;

  error.map((item) => tempErrorArray.push(item.extensions));
  SetError(tempErrorArray);
}

async function updateTempAgent(
  _id: string,
  name: string,
  surname: string,
  email: string,
  phone: string,
  street: string,
  city: string,
  zip: string,
  instaLink: string,
  facebookLink: string,
  tourTheme: Array<TourThema>,
  advertisingTheme: Array<string>,
  siteID: string,
  handleError: Function
): Promise<Boolean> {
  const input = {
    _id,
    name,
    surname,
    email_corp: email,
    phone,
    address: street,
    city,
    zip,
    advertisingTheme,
  };

  const arrayIdTourTheme = tourTheme.map((item) => item._id || '');

  const siteInput = {
    insta_link: instaLink,
    facebook_link: facebookLink,
    tourTheme: arrayIdTourTheme,
  };
  const updateAgent = await handleUpdateTempAgent(input, handleError);
  await handleUpdateSite(siteInput, siteID, handleError);
  if (updateAgent) {
    return true;
  } else return false;
}

async function createTempAgent(
  name: string,
  surname: string,
  email: string,
  phone: string,
  city: string,
  street: string,
  zip: string,
  country: string,
  advertisingTheme: Array<string>,
  tourTheme: Array<TourThema>,
  insta_link: string,
  facebook_link: string,
  handleError: Function
): Promise<Boolean> {
  const arrayIdTourTheme = tourTheme.map((item) => item._id);

  const input: inputTempAgent = {
    name,
    surname,
    email_corp: email,
    phone,
    city,
    address: street,
    zip,
    country,
    advertisingTheme,
    tourTheme: arrayIdTourTheme,
    insta_link,
    facebook_link,
    is_aktive: false,
  };
  const result = await handleCreateTempAgent(input, handleError);
  if (result) {
    return true;
  } else {
    return false;
  }
}

interface ComponentInput {
  selectedItem: TempAgent;
  editMode: Boolean;
  handleEditMode?: Function;
  handleOpenEdit: Function;
  triggerReload?: Function;
  resetSelectedItem?: Function;
}

export default function EditTempAgentForm({
  selectedItem,
  editMode,
  handleOpenEdit,
  triggerReload,
  resetSelectedItem,
  handleEditMode,
}: ComponentInput) {
  const classes = useStyles();
  const [tempAgentID, setTempAgentID] = useState('');
  const [errors, setErrors] = useState([]);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [avatar, setAvatar] = useState('');
  const [listTourThemes, setListTourThemes] = useState([]);
  const [listAdvertisingTheme, setListAdvertisingTheme] = useState([]);
  const [instaLink, setInstaLink] = useState('');
  const [facebookLink, setFacebookLink] = useState('');
  const [advertisingTheme, setAdvertisingTheme] = useState('');
  const [tourTheme, setTourTheme] = useState([]);
  const [isPublishedWebsite, setIsPublishedWebsite] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [route, setRoute] = useState('');
  const [siteID, setSiteID] = useState('');

  const handleClose = () => {
    handleOpenEdit(false);
    if (resetSelectedItem) resetSelectedItem();
    if (handleEditMode) handleEditMode();
    if (triggerReload) triggerReload();
  };

  useEffect(() => {
    if (selectedItem._id) setTempAgentID(selectedItem._id);
    if (selectedItem.name) setName(selectedItem.name);
    if (selectedItem.surname) setSurname(selectedItem.surname);
    if (selectedItem.email_corp) setEmail(selectedItem.email_corp);
    if (selectedItem.phone) setPhone(selectedItem.phone);
    if (selectedItem.address) setStreet(selectedItem.address);
    if (selectedItem.city) setCity(selectedItem.city);
    if (selectedItem.zip) setZip(selectedItem.zip);

    async function getData() {
      const arrayRequest = [getSiteByID(selectedItem._id), getAdvertisingTheme];
      await requestToServer('query', arrayRequest)!
        .then((result) => {
          console.log('Result ', result);
          if (result.getSiteByID) {
            setSiteID(result.getSiteByID._id);
          }

          if (result.getAdvertisingTheme) {
            setListAdvertisingTheme(result.getAdvertisingTheme);
          }
          if (result.getSiteByID.phone) {
            setPhone(result.getSiteByID.phone);
          }
          if (result.getSiteByID.route) {
            setRoute(result.getSiteByID.route);
          }
          if (result.getSiteByID.insta_link) {
            setInstaLink(result.getSiteByID.insta_link);
          }
          if (result.getSiteByID.facebook_link) {
            setFacebookLink(result.getSiteByID.facebook_link);
          }
          if (result.getSiteByID.agent_image) {
            setAvatar(result.getSiteByID.agent_image);
          }
          if (result.getSiteByID.agent[0].name) {
            setName(result.getSiteByID.agent[0].name);
          }
          if (result.getSiteByID.agent[0].surname) {
            setSurname(result.getSiteByID.agent[0].surname);
          }
          if (result.getSiteByID.advertisingTheme.length > 0) {
            setAdvertisingTheme(result.getSiteByID.advertisingTheme[0]._id);
          }
          if (result.getSiteByID.tourTheme) {
            console.log(
              'result.getSiteByID.tourTheme',
              result.getSiteByID.tourTheme
            );
            setTourTheme(result.getSiteByID.tourTheme);
          }
          if (result.getSiteByID.is_aktive) {
            setIsPublishedWebsite(result.getSiteByID.is_aktive);
          }
        })
        .catch((error) => {
          console.log(
            'There is requestToServer data Error in this AllParametrs request:',
            error
          );
          return [];
        });
    }
    async function getTourThema() {
      const arrayRequest = [getTourThemes];
      await requestToServer('query', arrayRequest)!.then((result) => {
        if (result.getTourThemes) {
          setListTourThemes(result.getTourThemes);
        }
      });
    }
    Promise.all([getData(), getTourThema()]).then(() => {
      setIsLoading(false);
    });
  }, [avatar, isPublishedWebsite]);

  console.log('Errors', errors);
  return (
    <Box className={classes.content}>
      <LoadingOverlay show={isLoading} />
      <div>
        <IconButton
          color='primary'
          onClick={() => {
            handleClose();
          }}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
      </div>
      <Box className={classes.RowBox}>
        <Typography className={classes.caption} component='span'>
          Externer Agent
        </Typography>
      </Box>
      <Box>
        {/* <Typography color="error">
          {errors.length !== 0 && errors[0]['MESSAGE']}
        </Typography> */}
      </Box>
      <Box className={classes.RowBox}>
        {editMode && (
          <>
            <UserAvatar
              agentID={selectedItem._id ? selectedItem._id : null}
              changeCurrentImageAgent={setAvatar}
              currentImageAgent={avatar}
            />
            <Box>
              Agent entfernen
              <DeleteDialog
                item={{ id: tempAgentID, name, surname }}
                deleteFunction={deleteExternAgent}
                triggerReload={() => {
                  handleClose();
                }}
              />
            </Box>
          </>
        )}
      </Box>
      {editMode && (
        <Box>
          <Box>
            <a
              href={'https://rita.travel' + route}
              target='_blank'
              rel='noreferrer'
            >
              {'https://rita.travel' + route}
            </a>
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={isPublishedWebsite}
                disabled={isLoading}
                onChange={async () => {
                  const result = await handleSiteStatus(
                    siteID,
                    isPublishedWebsite
                  );
                  if (result) {
                    setIsPublishedWebsite(!isPublishedWebsite);
                  }
                }}
                color='primary'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label='Webseite Aktiv'
          />
        </Box>
      )}
      <Box className={classes.form}>
        <Box className={classes.input}>
          <TextField
            className={classes.input}
            id='name'
            variant='filled'
            label='Name'
            onChange={(event) => {
              setName(event.target.value);
            }}
            value={name}
            name='name'
            helperText='min 2 max 60'
            error={!Boolean(name.length > 1 && name.length < 61)}
          />
          <TextField
            className={classes.input}
            id='sureName'
            variant='filled'
            label='Nachname'
            onChange={(event) => {
              setSurname(event.target.value);
            }}
            value={surname}
            name='sureName'
            helperText='min 2 max 60'
            error={!Boolean(surname.length > 1 && surname.length < 61)}
          />
          <TextField
            className={classes.input}
            id='email'
            variant='filled'
            label='E-Mail'
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            value={email}
            name='email'
            helperText='min 5 max 60'
            error={!Boolean(email.length > 4 && email.length < 61)}
          />
          <TextField
            className={classes.input}
            id='phone'
            variant='filled'
            label='Telefon'
            onChange={(event) => {
              setPhone(event.target.value);
            }}
            value={phone}
            name='phone'
            helperText='min 5 max 60'
            error={!Boolean(phone.length > 4 && phone.length < 61)}
          />
          <TextField
            className={classes.input}
            id='adress'
            variant='filled'
            label='Strasse + Hausnummer'
            onChange={(event) => {
              setStreet(event.target.value);
            }}
            value={street}
            name='adress'
            helperText='min 5 max 60'
            error={!Boolean(street.length > 4 && street.length < 61)}
          />
          <TextField
            className={classes.input}
            id='city'
            variant='filled'
            label='Stadt'
            onChange={(event) => {
              setCity(event.target.value);
            }}
            value={city}
            name='city'
            helperText='min 5 max 60'
            error={!Boolean(city.length > 4 && city.length < 61)}
          />

          <TextField
            className={classes.input}
            id='zip'
            variant='filled'
            label='PLZ'
            onChange={(event) => {
              setZip(event.target.value);
            }}
            value={zip}
            name='zip'
            helperText='min 4'
            error={!Boolean(zip.length > 3)}
          />
          {editMode && (
            <>
              <TextField
                className={classes.input}
                id='instaLink'
                variant='filled'
                label='Instagram'
                onChange={(event) => {
                  setInstaLink(event.target.value);
                }}
                value={instaLink}
                name='instaLink'
                helperText='Beispiel: https://www.instagram.com/mein_urlaubsglueck/'
                // error={!Boolean(instaLink.length > 10)}
              />
              <TextField
                className={classes.input}
                id='facebookLink'
                variant='filled'
                label='Facebook'
                onChange={(event) => {
                  setFacebookLink(event.target.value);
                }}
                value={facebookLink}
                name='facebookLink'
                helperText='Beispiel: https://www.facebook.com/meinurlaubsglueck/'
                // error={!Boolean(instaLink.length > 10)}
              />
            </>
          )}
        </Box>
      </Box>
      {editMode && (
        <Box className={classes.ItemBox}>
          <Typography className={classes.caption} component='span'>
            Meine Themen
          </Typography>
          <ListTourTheme
            listTourThemes={listTourThemes}
            changeTourTheme={setTourTheme}
            tourTheme={tourTheme}
          />

          {/* Vorrübergehend auskommentiert da keine Werbung
            <SelectUI
            inputTitle='Werbethema'
            currentValue={advertisingTheme}
            changeCurrentValue={setAdvertisingTheme}
            arrayValues={listAdvertisingTheme}
            required={true}
          /> */}
        </Box>
      )}
      <Box>
        <PrimaryButtonMedium
          onClick={async () => {
            setIsLoading(true);
            let isReady;
            if (editMode) {
              setErrors([]);
              isReady = await updateTempAgent(
                tempAgentID,
                name,
                surname,
                email,
                phone,
                street,
                city,
                zip,
                instaLink,
                facebookLink,
                tourTheme,
                [advertisingTheme],
                siteID,
                (err: Array<any>) => {
                  handleErrors(err, errors, setErrors);
                  console.log('Errors', err);
                }
              );
            } else {
              setErrors([]);
              isReady = await createTempAgent(
                name,
                surname,
                email,
                phone,
                city,
                street,
                zip,
                'Deustchland',
                [advertisingTheme],
                tourTheme,
                instaLink,
                facebookLink,
                (err: Array<any>) => {
                  handleErrors(err, errors, setErrors);
                  console.log('Errors', err);
                }
              );
            }
            if (isReady) {
              setIsLoading(false);
              handleClose();
            } else {
              setIsLoading(false);
            }
          }}
        >
          {editMode ? `+ Änderung Speichern` : `+ Berater hinzufügen`}
        </PrimaryButtonMedium>
      </Box>
    </Box>
  );
}
