import React from 'react';
import styled from 'styled-components';

const LabelBox = styled.span<{ color: string }>`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => {
    if (props.color) {
      return props.color;
    } else {
      return '#383838';
    }
  }};
`;

interface input {
  children: any;
  color?: string;
}

export default function Label({ children, color = '#333333' }: input) {
  return <LabelBox color={color}>{children}</LabelBox>;
}
