import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import HeaderColorLogoAER from '../static/headerColorLogoAER.png';
import HeaderColorLogoMUGL from '../static/headerColorLogoMUGL.png';
import HeaderColorLogoTRAV from '../static/headerColorLogoTRAV.png';
import HeaderLogoCockpit from '../static/images/Logo.png';
import PhotoThanyou from '../static/images/PhotoThanyou.png';
import Heading2 from '../components/UI/Typography/Heading2';
import TextBody18 from '../components/UI/Typography/TextBody18';

let userInfo = localStorage.getItem('user');
let userInfoAfterParse = JSON.parse(userInfo);

/**
 * Function to chose header logo depend on office_id of user

 * @param {Number} office_id
 * @default CockpitHeaderLogo
 * @returns {String} Name of header logo

 */

function logoChoser(office_id) {
  const logoList = [
    HeaderColorLogoMUGL,
    HeaderColorLogoTRAV,
    HeaderColorLogoAER,
    HeaderLogoCockpit,
  ];
  let logo = null;
  switch (office_id) {
    case 1:
      logo = logoList[0];
      break;
    case 2:
      logo = logoList[1];
      break;
    case 3:
      logo = logoList[2];
      break;
    default:
      logo = logoList[3];
      break;
  }
  return logo;
}

export default function ThanyouPage() {
  let { email, phone } = useParams();

  return (
    <Block>
      <LogoBox>
        <ImageLogo
          src={logoChoser(userInfoAfterParse?.branch_office_id)}
          alt='logo'
        />
      </LogoBox>
      <BlockThanyou>
        <BlockThanyouLetter>
          <ThanyouTextBlock>
            <Heading2 color='#333333'>Vielen Dank!</Heading2>
            <br />
            <TextBody18 color='#B9B9B9'>
              Gerne informiere ich Sie über interessante Reisethemen und
              Angebote.
              <br />
              <br />
              Wenn Sie noch Fragen haben, können Sie mich jederzeit per Telefon{' '}
              <Link>{phone}</Link> oder per E-Mail erreichen{' '}
              <Link>{email}</Link>.
            </TextBody18>
          </ThanyouTextBlock>
        </BlockThanyouLetter>
        <BlockThanyouPicture>
          <Image src={PhotoThanyou} alt='image' />
        </BlockThanyouPicture>
      </BlockThanyou>
    </Block>
  );
}

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 1024px;
  background: #ffffff;
  @media (max-width: 834px) {
    height: 100%;
  }
`;

const LogoBox = styled.div`
  display: flex;
  align-items: center;
  width: 1200px;
  height: 92px;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 414px) {
    height: 72px;
  }
`;

const ImageLogo = styled.img`
  width: auto;
  height: auto;
  left: 16px;
  top: 16px;
  @media (max-width: 834px) {
    width: auto;
    height: auto;
  }
  @media (max-width: 414px) {
    width: auto;
    height: auto;
  }
`;

const BlockThanyou = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  width: 1178px;
  height: 670px;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 834px) {
    height: 570px;
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
    height: 100%;
  }
`;

const BlockThanyouLetter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 670px;
  @media (max-width: 834px) {
    height: 470px;
    align-items: flex-start;
  }
  @media (max-width: 600px) {
    height: 100%;
  }
`;

const BlockThanyouPicture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  width: 541px;
  height: 670px;
  @media (max-width: 834px) {
    height: 470px;
    align-items: flex-start;
  }
  @media (max-width: 414px) {
    height: 100%;
  }
`;

const Image = styled.img`
  width: 541px;
  height: 670px;
  padding-top: 24px;
  @media (max-width: 1200px) {
    width: 46vw;
    height: auto;
  }
  @media (max-width: 600px) {
    width: 90vw;
    height: auto;
  }
`;

const ThanyouTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 186px;
  width: 100%;
  padding: 24px 0 24px 0;
  @media (max-width: 834px) {
    height: 250px;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: #333333;
`;
