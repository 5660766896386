import React, { useState, useEffect } from 'react';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import SignInPage from './pages/SignInPage';
import ArticlesListPage from './pages/ArticlesListPage';
import ArticlesListPageModeration from './pages/ArticlesListPageModeration';
import TempAgentPageModeration from './pages/TempAgentPageModeration';
import EditArticlePage from './pages/EditArticlePage';
import LogOut from './components/LogOut';
import { getCurrentUser } from '../src/utilites/authUser';
import StartPage from './pages/StartPage';
import WikiPage from './pages/WikiPage';
import EkukoPage from './pages/EkukoPage';
import ExpiredPage from './pages/ExpiredPage';
import Page404 from './pages/Page404';
import ApprovePage from './pages/ApprovePage';
import ThankYouPage from './pages/ThankyouPage';

const ServerLink = createUploadLink({
  uri:
    window.location.pathname === '/mypage/ekuko/'
      ? 'https://cockpit.mein-urlaubsglueck.de/ekuko-backend'
      : '/backend',
});

const authLink = setContext((_, { headers }) => {
  let token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      'Access-Control-Allow-Credentials': true,
      authorization: `bearer ${token}`,
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(ServerLink),
});

function App() {
  const [isRedactor, setIsRedactor] = useState(false);

  useEffect(() => {
    const user = getCurrentUser();
    if (user.is_redactor) {
      setIsRedactor(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <Switch>
          <Route
            exact
            path='/mypage'
            render={(props) => {
              if (!getCurrentUser()) return <Redirect to='/mypage/login' />;
              return <StartPage isRedactor={isRedactor} />;
            }}
          />
          <Route
            exact
            path='/wiki'
            render={(props) => {
              if (!getCurrentUser()) return <Redirect to='/mypage/login' />;
              return <WikiPage isRedactor={isRedactor} />;
            }}
          />
          <Route
            exact
            path='/mypage/homepage'
            render={(props) => {
              if (!getCurrentUser()) return <Redirect to='/mypage/login' />;
              return <ArticlesListPage isRedactor={isRedactor} />;
            }}
          />
          <Route
            exact
            path='/mypage/moderation'
            render={(props) => {
              if (!getCurrentUser()) return <Redirect to='/mypage/login' />;
              return <ArticlesListPageModeration isRedactor={isRedactor} />;
            }}
          />
          <Route
            exact
            path='/mypage/temp-agents'
            render={(props) => {
              if (!getCurrentUser()) return <Redirect to='/mypage/login' />;
              return <TempAgentPageModeration isRedactor={isRedactor} />;
            }}
          />
          <Route
            exact
            path='/mypage/ekuko'
            render={(props) => {
              if (!getCurrentUser()) return <Redirect to='/mypage/login' />;
              return <EkukoPage isRedactor={isRedactor} />;
            }}
          />
          <Route
            exact
            path='/mypage/ekuko/expired'
            render={() => {
              return <ExpiredPage />;
            }}
          />
          <Route
            exact
            path='/mypage/ekuko/confirmationform/:id'
            render={() => {
              return <ApprovePage />;
            }}
          />
          <Route
            exact
            path='/mypage/ekuko/thankyou/:email/:phone'
            render={() => {
              return <ThankYouPage />;
            }}
          />
          <Route
            path='/mypage/edit-article/:number'
            render={(props) => {
              if (!getCurrentUser()) return <Redirect to='/mypage/login' />;
              return (
                <EditArticlePage
                  isRedactor={isRedactor}
                  idArticle={props.match.params.number}
                />
              );
            }}
          />
          <Route
            path='/mypage/edit-published-article/:number'
            render={(props) => {
              if (!getCurrentUser()) return <Redirect to='/mypage/login' />;
              return (
                <EditArticlePage
                  isPublished={true}
                  isRedactor={isRedactor}
                  idArticle={props.match.params.number}
                />
              );
            }}
          />
          <Route
            path='/mypage/add-article'
            render={(props) => {
              if (!getCurrentUser()) return <Redirect to='/mypage/login' />;
              if (isRedactor) return <Redirect to='/moderation' />;
              return <EditArticlePage />;
            }}
          />
          <Route
            path='/mypage/login'
            render={() => {
              if (getCurrentUser()) return <Redirect to='/mypage' />;
              return <SignInPage />;
            }}
          />
          <Route path='/mypage/logout' component={LogOut} />
          <Route
            render={() => {
              return <Page404 />;
            }}
          />
        </Switch>
      </ApolloProvider>
    </BrowserRouter>
  );
}

export default App;
