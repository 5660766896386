import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import ButtonOutlinedIconLeft from '../UI/ButtonOutlinedIconLeft';
import { Microsoftexcel } from '@styled-icons/simple-icons/Microsoftexcel';
import ThemeOption from '../../utilites/config/themeOption';
import Customer from '../../types/Customer.types';

type props = {
  csvData: Customer[];
  fileName: string;
};

const ExportCSV = ({ csvData, fileName }: props) => {
  if (!csvData) csvData = [];
  csvData = csvData.filter((item) => item.newsletter);
  // @ts-ignore
  csvData = csvData.map((item) => {
    return { 'E-Mail´s für Kunden Newsletter': item.email };
  });

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const exportToCSV = (csvData: Customer[], fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <ButtonOutlinedIconLeft
      icon={<Microsoftexcel size={20} style={{ marginRight: '10px' }} />}
      size={'small'}
      onClick={() => exportToCSV(csvData, fileName)}
      // @ts-ignore
      style={{ borderRadius: ThemeOption.btn.borderRadius }}
    >
      Export für Newsletterversand
    </ButtonOutlinedIconLeft>
  );
};

export default ExportCSV;
