import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import ThemeOption from '../../utilites/config/themeOption';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';

const useStyles = makeStyles((theme) => ({
  BrandPageBox: {
    display: 'inline-flex',
    alignItems: 'center',
    background: theme.palette.primary.main,
    padding: '16px 28px',
    marginBottom: '20px',
  },
  iconBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '80px',
    width: '80px',
    height: '80px',
    borderRadius: '20px',
    border: '3px solid #ffffff',
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '48px',
      width: '48px',
      height: '48px',
      marginRight: '10px',
    },
  },
  icon: {
    display: 'block',
    fontSize: '52px',
    cursor: 'pointer',
    color: '#ffffff',
    [theme.breakpoints.down('xs')]: {
      fontSize: '34px',
    },
  },
  BrandText: {
    color: '#ffffff',
    fontSize: '70px',
    textTransform: 'uppercase',
    fontFamily: "'Poppins', sans-serif",
    [theme.breakpoints.down('sm')]: {
      fontSize: '48px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      textTransform: 'capitalize',
    },
  },
}));

interface Props {
  pageName: string;
}

export default function BrandPage({ pageName }: Props) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={ThemeOption}>
      <Box className={classes.BrandPageBox}>
        {pageName === 'Homepage' && (
          <Box className={classes.iconBox}>
            <AccountCircleOutlinedIcon className={classes.icon} />
          </Box>
        )}
        {pageName === 'Temporäre Agenten' && (
          <Box className={classes.iconBox}>
            <SupervisorAccountIcon className={classes.icon} />
          </Box>
        )}
        {pageName === 'Berichte' && (
          <Box className={classes.iconBox}>
            <AssignmentIcon className={classes.icon} />
          </Box>
        )}
        {pageName === 'Ekuko' && (
          <Box className={classes.iconBox}>
            <LibraryAddCheckIcon className={classes.icon} />
          </Box>
        )}
        <Typography className={classes.BrandText} component='h1'>
          {pageName}
        </Typography>
      </Box>
    </ThemeProvider>
  );
}
