import React, { useState, useEffect } from "react";

import Header from "../components/blocks/Header";
import BrandPage from "../components/blocks/BrandPage";
import ThemeOption from "../utilites/config/themeOption";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import requestToServer from "../utilites/requestToServer";
import LoadingOverlay from "../components/blocks/LoadingOverlay";
import TempAgentTable from "../components/blocks/TempAgents/TempAgentTable";
import EditTempAgentForm from "../components/blocks/TempAgents/EditTempAgentForm";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "block",
    maxWidth: "1200px",
    margin: "40px auto",
    boxSizing: "border-box",
  },
  RowBox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginBottom: "10px",
    },
  },

  Input: {
    width: "100%",
  },
  caption: {
    color: "#333333",
    fontSize: "28px",
    lineHeight: "32px",
    fontFamily: "'Poppins', sans-serif",
  },
  table: {
    display: "flex",
    width: "570px",
  },
  tableRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "50px",
    background: "#FFF",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
    marginTop: "30px",
    marginBottom: "8px",
  },
}));

interface Props {
  isRedactor: boolean;
}

export default function ArticlesListPageModeration({ isRedactor }: Props) {
  const classes = useStyles();

  const [triggerReload, setTriggerReload] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [listTempAgents, setListTempAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState({
    _id: "",
    address: "",
    branch_office: "",
    city: "",
    country: "",
    email_corp: "",
    name: "",
    phone: "",
    surname: "",
    zip: "",
  });
  const [openEdit, setOpenEdit] = useState(false);

  function handleReload(): void {
    setTriggerReload(!triggerReload);
  }

  function resetSelectedItem() {
    setSelectedItem({
      _id: "",
      address: "",
      branch_office: "",
      city: "",
      country: "",
      email_corp: "",
      name: "",
      phone: "",
      surname: "",
      zip: "",
    });
  }

  useEffect(() => {
    async function getData() {
      requestToServer(
        "query",
        `getAllTempAgent{_id,branch_office,name,surname,email_corp,phone,country,address,zip,city}`,
        []
      )!
        .then((result) => {
          setListTempAgents(result.getAllTempAgent);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          return [];
        });
    }
    getData();
  }, [triggerReload]);

  return (
    <ThemeProvider theme={ThemeOption}>
      <LoadingOverlay show={isLoading} />
      <Header isRedactor={isRedactor} />
      <Box className={classes.content}>
        <BrandPage pageName={"Temporäre Agenten"} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {openEdit ? (
              <EditTempAgentForm
                selectedItem={selectedItem}
                resetSelectedItem={resetSelectedItem}
                editMode={editMode}
                handleEditMode={setEditMode}
                handleOpenEdit={setOpenEdit}
                triggerReload={handleReload}
              />
            ) : (
              <TempAgentTable
                list={listTempAgents}
                handleEditMode={setEditMode}
                handleOpenEdit={setOpenEdit}
                getSelectedItem={setSelectedItem}
                triggerReload={handleReload}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
