import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const CustomButton = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 20,
    padding: "8px 16px",
    borderRadius: theme.btn?.borderRadius,
    lineHeight: 1.2,
    background: "#D93D23",
    color: "#ffffff",
    fontFamily: ['"Poppins", sans-serif'].join(","),
    "&:hover": {
      background: "#d95742",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      background: "#D93D23",
    },
    "&:focus": {},
  },
}))(Button);

export default function ErrorButtonMedium(prop) {
  return (
    <CustomButton
      onClick={prop.onClick}
      disabled={prop.disabled}
      type={prop.type}
      variant="contained"
    >
      {prop.children}
    </CustomButton>
  );
}
