import deleteTempAgent from '../utilites/queryBodies/deleteTempAgent';
import requestToServer from './requestToServer';

export default function deleteExternAgent(id: string, callBack?: Function) {
  requestToServer('mutation', deleteTempAgent, { id })!.then((result) => {
    if (callBack) {
      callBack();
    }
  });
}
