import React, { useState } from 'react';
import PrimaryButtonMedium from '../UI/PrimaryButtonMedium';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

interface item {
  id: string;
  name?: string;
  surname?: string;
}

interface ComponentInput {
  item: item;
  deleteFunction: Function;
  triggerReload?: Function;
}

export default function DeleteDialog({
  item,
  deleteFunction,
  triggerReload,
}: ComponentInput) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <DeleteOutlineOutlinedIcon
        style={{ cursor: 'pointer' }}
        htmlColor={'red'}
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Externen Agenten Löschen'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Wollen Sie {item.name} {item.surname} wirklich Löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <PrimaryButtonMedium
            onClick={async () => {
              if (triggerReload) {
                await deleteFunction(item.id, triggerReload);
              } else {
                await deleteFunction(item.id);
              }
              handleClose();
            }}
            color='primary'
          >
            Löschen
          </PrimaryButtonMedium>
          <PrimaryButtonMedium onClick={handleClose} color='primary' autoFocus>
            Abbrechen
          </PrimaryButtonMedium>
        </DialogActions>
      </Dialog>
    </div>
  );
}
