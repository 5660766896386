import React from 'react';
import styled from 'styled-components';
import { Spinner3 } from '@styled-icons/evil/Spinner3';
import { darken, lighten } from 'polished';
import ThemeOption from '../../utilites/config/themeOption';

const colorbutton = ThemeOption.palette.secondary.main;

const ButtonTextOnlyBox = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: ${(props) => {
    if (props.style?.background) {
      return props.style.background;
    } else {
      return colorbutton;
    }
  }};
  border-radius: 8px;
  border: none;
  outline: none;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: ${(props) => {
    if (props.size === 'large') {
      return '500';
    } else {
      return 'normal';
    }
  }};
  font-size: ${(props) => {
    if (props.size === 'large') {
      return '24px';
    } else if (props.size === 'medium') {
      return '20px';
    } else if (props.size === 'small') {
      return '14px';
    } else {
      return '24px';
    }
  }};
  line-height: ${(props) => {
    if (props.size === 'large') {
      return '32px';
    } else if (props.size === 'medium') {
      return '24px';
    } else if (props.size === 'small') {
      return '14px';
    } else {
      return '24px';
    }
  }};
  letter-spacing: 1px;
  color: #ffffff;
  outline: none;
  cursor: pointer;

  &:hover {
    background: ${(props) => lighten(0.15, colorbutton)};
    transition: all 0.3s ease-out;
  }

  &:active {
    background: ${(props) => darken(0.1, colorbutton)};
    transition: all 0.3s ease-out;
  }

  &:disabled {
    background: ${(props) =>
      lighten(
        0.35,
        props.style.background ? props.style.background : colorbutton
      )};
    transition: all 0.3s ease-out;
    cursor: default;
  }
`;

const Content = styled.span`
  opacity: ${(props) => {
    if (props.isLoading) {
      return 0;
    } else {
      return 1;
    }
  }};
`;

const IconSpinner3 = styled(Spinner3)`
  color: inherit;
  position: absolute;
  animation-name: rotation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  width: ${(props) => {
    if (props.size === 'large') {
      return '36px';
    } else if (props.size === 'medium') {
      return '26px';
    } else if (props.size === 'small') {
      return '20px';
    } else {
      return '26px';
    }
  }};
  height: ${(props) => {
    if (props.size === 'large') {
      return '36px';
    } else if (props.size === 'medium') {
      return '26px';
    } else if (props.size === 'small') {
      return '20px';
    } else {
      return '26px';
    }
  }};
`;

export default function ButtonTextOnly({
  onClick,
  size = 'large',
  children = 'Button',
  style = { background: '' },
  isDisabled = false,
  isLoading = false,
}) {
  return (
    <ButtonTextOnlyBox
      onClick={onClick}
      size={size}
      style={style}
      disabled={isDisabled}
    >
      {isLoading && <IconSpinner3 size={size} />}
      <Content isLoading={isLoading}>{children}</Content>
    </ButtonTextOnlyBox>
  );
}
