import React from 'react';
import styled from 'styled-components';

const HeadingTwo = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  color: ${(props) => {
    if (props.color) {
      return props.color;
    } else {
      return '#383838';
    }
  }};
  margin: 0;
`;

export default function Heading2({ children, color }) {
  return <HeadingTwo color={color}>{children}</HeadingTwo>;
}
