import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HeaderColorLogoAER from '../static/headerColorLogoAER.png';
import HeaderColorLogoMUGL from '../static/headerColorLogoMUGL.png';
import HeaderColorLogoTRAV from '../static/headerColorLogoTRAV.png';
import PexelsKarolina from '../static/PexelsKarolina.png';
import Heading2 from '../components/UI/Typography/Heading2';
import Heading5 from '../components/UI/Typography/Heading5';
import TextBody18 from '../components/UI/Typography/TextBody18';
import ButtonTextOnly from '../components/UI/ButtonTextOnly';
import CheckBox from '../components/UI/CheckBox';
import { Mail } from '@styled-icons/heroicons-outline/Mail';
import { Smartphone } from '@styled-icons/material-outlined/Smartphone';
import { AlternateEmail } from '@styled-icons/material-rounded/AlternateEmail';
import { Megaphone } from '@styled-icons/octicons/Megaphone';
import GetDataForConfirmationQuery from '../utilites/Apollo/query/getDataForConfirmation';
import CustomerResponseQuery from '../utilites/Apollo/mutation/customerResponse';
import { guestClient } from '../utilites/config/config';
import ThemeOption from '../utilites/config/themeOption';

const logo = {
  'mein-urlaubsglueck.de': HeaderColorLogoMUGL,
  'mytravelista.de': HeaderColorLogoTRAV,
  'aer-mobile.de': HeaderColorLogoAER,
};

const user = localStorage.getItem('user');

let branchOfficeId = null;

if (user) {
  const { branch_office_id } = JSON.parse(user);
  branchOfficeId = branch_office_id;
}

function getDomain() {
  let themeVariation = null;
  switch (branchOfficeId) {
    case 1:
      themeVariation = 'mein-urlaubsglueck.de';
      break;
    case 2:
      themeVariation = 'mytravelista.de';
      break;
    case 3:
      themeVariation = 'aer-mobile.de';
      break;
    default:
      themeVariation = 'mein-urlaubsglueck.de';
  }

  return themeVariation;
}

export default function ApprovePage() {
  const history = useHistory();
  const [mailing, setMailing] = useState(false);
  const [phoneCalls, setPhoneCalls] = useState(false);
  const [digitalCommunication, setDigitalCommunication] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const domain = getDomain();

  const { id } = useParams();

  const { data, loading, error } = useQuery(GetDataForConfirmationQuery, {
    client: guestClient,
    variables: { input: { hashLink: id } },
  });

  const [customerResponse, { loading: loadingCustomerResponse }] = useMutation(
    CustomerResponseQuery,
    {
      client: guestClient,
      variables: {
        input: {
          hashLink: id,
          mailing,
          phoneCalls,
          digitalCommunication,
          newsletter,
        },
      },
    }
  );

  const {
    agentPhoto,
    agentName,
    agentSurname,
    salutation,
    customerName,
    customerSurname,
    customerIs_male,
    agentContactPhone,
    agentContactEmail,
  } = data?.getDataForConfirmation || {};

  // TODO phone Bib. für Valiedierung benutzen
  const formatedPhone = agentContactPhone?.replace(/[^a-zA-Z0-9]/g, '');

  useEffect(() => {
    if (error) {
      history.push('/ekuko/expired');
    }
  }, [error]);

  const text = (() => {
    if (customerIs_male && !salutation) return 'Lieber ' + customerName;
    if (customerIs_male && salutation)
      return 'Sehr geehrter Herr ' + customerSurname;
    if (!customerIs_male && !salutation) return 'Liebe ' + customerName;
    if (!customerIs_male && salutation)
      return 'Sehr geehrte Frau ' + customerSurname;
  })();

  return (
    <>
      {!error && !loading && (
        <Block>
          {branchOfficeId ? (
            <LogoBox>
              <ImageLogo src={logo[domain]} alt="logo" />
            </LogoBox>
          ) : (
            <LogoBox />
          )}
          <BlockApprove>
            {data && (
              <BlockApproveLetter>
                <AppealTextBlock>
                  <Heading2 color="#333333">{text}!</Heading2>
                  <BoxText>
                    {agentPhoto !== 'false' && (
                      <PhotoBox src={agentPhoto} alt="photo" />
                    )}
                    <Heading5 color="#666666">
                      Hallo , hier ist {agentName} {agentSurname}!
                    </Heading5>
                  </BoxText>
                  <TextBody18 color="#666666">
                    {salutation &&
                      'Bitte wählen Sie die Kommunikationskanäle aus, über die ich Informationen und Angebote an Sie schicken darf:'}
                    {!salutation &&
                      'Bitte wähle die Kommunikationskanäle aus, über die ich Informationen und Angebote an dich senden darf:'}
                  </TextBody18>
                </AppealTextBlock>
                <ApproveItemBlock>
                  <CheckboxBlock>
                    <CheckboxItem>
                      <IconMail
                        style={{ color: ThemeOption.palette.primary.main }}
                      />
                      <CheckBox
                        label="Mail"
                        initValue={mailing}
                        getValue={setMailing}
                      />
                    </CheckboxItem>
                    <CheckboxItem>
                      <IconSmartphone
                        style={{ color: ThemeOption.palette.primary.main }}
                      />
                      <CheckBox
                        label="Telefon"
                        initValue={phoneCalls}
                        getValue={setPhoneCalls}
                      />
                    </CheckboxItem>
                    <CheckboxItem>
                      <IconAlternateEmail
                        style={{ color: ThemeOption.palette.primary.main }}
                      />
                      <CheckBox
                        label="WhatsApp, Facebook, Instagram"
                        initValue={digitalCommunication}
                        getValue={setDigitalCommunication}
                      />
                    </CheckboxItem>
                    <CheckboxItem>
                      <IconMegaphone
                        style={{ color: ThemeOption.palette.primary.main }}
                      />
                      <CheckBox
                        label="Newsletter"
                        initValue={newsletter}
                        getValue={setNewsletter}
                      />
                    </CheckboxItem>
                  </CheckboxBlock>
                  <ButtonTextOnly
                    style={{ borderRadius: ThemeOption.btn.borderRadius }}
                    onClick={() => {
                      customerResponse();
                      history.push(
                        `/mypage/ekuko/thankyou/${agentContactEmail}/${formatedPhone}`
                      );
                    }}
                    isLoading={loadingCustomerResponse}
                    size={'large'}
                    children={'Ich stimme zu'}
                  />
                </ApproveItemBlock>
              </BlockApproveLetter>
            )}
            <BlockApprovePicture>
              <Image src={PexelsKarolina} alt="image" />
            </BlockApprovePicture>
          </BlockApprove>
        </Block>
      )}
    </>
  );
}

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 1024px;
  background: #ffffff;
  @media (max-width: 834px) {
    height: 100%;
  }
`;

const LogoBox = styled.div`
  display: flex;
  align-items: center;
  width: 1200px;
  height: 92px;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 414px) {
    height: 72px;
  }
`;

const ImageLogo = styled.img`
  width: auto;
  height: auto;

  left: 16px;
  top: 16px;
  @media (max-width: 834px) {
    width: auto;
    height: auto;
  }
  @media (max-width: 414px) {
    width: auto;
    height: auto;
  }
`;

const BlockApprove = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  width: 1200px;
  height: 670px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const BlockApproveLetter = styled.div`
  display: flex;
  flex-direction: column;
  width: 570px;
  height: 670px;
`;

const BlockApprovePicture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  width: 570px;
  height: 670px;
  @media (max-width: 1200px) {
    width: 28vw;
    height: 100%;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

const Image = styled.img`
  width: 570px;
  height: 670px;
  @media (max-width: 1200px) {
    width: 28vw;
    height: auto;
  }
`;

const AppealTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 234px;
  padding-top: 24px;
  @media (max-width: 600px) {
    height: auto;
    padding: 16px 0 16px 0;
  }
`;

const BoxText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 80px;
  @media (max-width: 600px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

const PhotoBox = styled.img`
  width: 80px;
  height: 80px;
  padding: 0 14px 0 0;
`;

const ApproveItemBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 24px;
  width: 100%;
  height: 336px;
`;

const CheckboxBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 240px;
  padding-top: 53px;
  padding-bottom: 48px;
  @media (max-width: 600px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const CheckboxItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 24px;
`;

const IconMail = styled(Mail)`
  color: #428dcb;
  width: 24px;
  height: 24px;
  padding-right: 8px;
`;

const IconSmartphone = styled(Smartphone)`
  color: #428dcb;
  width: 24px;
  height: 24px;
  padding-right: 8px;
`;

const IconAlternateEmail = styled(AlternateEmail)`
  color: #428dcb;
  width: 24px;
  height: 24px;
  padding-right: 8px;
`;
const IconMegaphone = styled(Megaphone)`
  color: #428dcb;
  width: 22px;
  height: 24px;
  padding-right: 8px;
`;
