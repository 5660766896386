import { EkukoIntranetLink } from '../interfaces/EkukoIntranetLink.interface';

const intranetLinks: EkukoIntranetLink[] = [
  {
    id: 1,
    link: 'https://ritaag.sharepoint.com/sites/IntranetMeinUrlaubsglck2',
  },
  {
    id: 2,
    link: 'https://ritaag.sharepoint.com/sites/Intranettravelista',
  },
  {
    id: 3,
    link: 'https://ritaag.sharepoint.com/sites/IntranetAERMobile2 ',
  },
  {
    id: 4,
    link: 'https://ritaag.sharepoint.com/sites/IntranetMeinUrlaubsglck2',
  },
];

export default intranetLinks;
