import { url } from './config/config';

const backednURL = url + '/backend';

/**
 *
 * @param {File} image
 * @param {String} x
 * @param {String} y
 * @param {String} width
 * @param {String} height
 * @param {String} [agentID]
 */

const uploadImageAgent = async (
  image,
  x = '0',
  y = '0',
  width,
  height,
  agentID
) => {
  const queryBody = `{"query":"mutation($image: Upload!){uploadCroppedAgentImage(image: $image, x:\\"${x}\\", y:\\"${y}\\", width:\\"${width}\\", height:\\"${height}\\", agentID:\\"${agentID}\\" )}","variables":{"image":null},"operationName":null}`;
  const map = `{"0":["variables.image"]}`;
  let form = new FormData();
  form.append('operations', queryBody);
  form.append('map', map);
  form.append('0', image);

  return fetch(backednURL, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem('token'),
      Accept: 'application/json',
    },
    method: 'POST',
    body: form,
  })
    .then((responce) => responce.json())
    .then((result) => {
      return result.data.setEmployeeImage;
    })
    .catch((err) => {
      console.error(err);
    });
};
export default uploadImageAgent;
