import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import ThemeOption from '../../utilites/config/themeOption';
import { Download } from '@styled-icons/bootstrap/Download';
import { Box, Modal } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ButtonOutlinedIconLeft from '../UI/ButtonOutlinedIconLeft';
import CloseIcon from '@material-ui/icons/Close';
import H2 from '../UI/Typography/Heading2';
import ButtonIconLeft from '../UI/ButtonIconLeft';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ImportCustomersQuery from '../../utilites/Apollo/mutation/importCustomers';

const useStyles = makeStyles((theme) => ({
  Avatar: {
    display: 'block',
    width: '80px',
    height: '80px',
    borderRadius: '80px',
    marginRight: '20px',
    objectFit: 'cover',
  },
  icon: {
    display: 'block',
    fontSize: '32px',
    cursor: 'pointer',
    color: '#ffffff',
    '&:hover': { color: '#54BFD0' },
  },
  input: {
    display: 'none',
  },
  ModalContent: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    width: '450px',
    height: '350px',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%,-50%)`,
    background: 'white',
  },
  buttons: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  ab: {
    backgroundColor: '#54BFD0',
  },
}));

function ImportModal({ refetch }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(false);

  const [importCustomer, { loading }] = useMutation(ImportCustomersQuery, {
    onError: (error) => {
      console.error(error);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  return (
    <>
      <Box>
        <ButtonOutlinedIconLeft
          style={{
            borderRadius: ThemeOption.btn.borderRadius,
          }}
          size={'small'}
          onClick={handleOpen}
          icon={<Download size={20} style={{ marginRight: '10px' }} />}
        >
          Kunden Import
        </ButtonOutlinedIconLeft>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box className={classes.ModalContent}>
          <input
            type='file'
            accept='application/JSON'
            onChange={onInputChange}
            hidden={true}
            name='importFile'
            id='importFile'
          />
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'flex-end'}
            onClick={() => {
              setOpen(false);
              setFile(false);
            }}
          >
            <CloseIcon />
          </Box>
          <Box display='flex' justifyContent='center'>
            <H2>IMPORT</H2>
          </Box>
          <Box display='flex' justifyContent='center'>
            Importieren Sie Ihre Kunden
          </Box>
          <Box marginTop={'30px'} display='flex'>
            <Box width={'30px'} marginRight={'10px'}>
              <H2>1.</H2>
            </Box>
            <Box width={'170px'} display={'flex'}>
              <ButtonIconLeft
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById('importFile').click();
                }}
                icon={<FileCopyIcon />}
                size='small'
              >
                Datei Wählen
              </ButtonIconLeft>
            </Box>
            <Box
              style={{
                width: '220px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {file && (
                <>
                  <CheckIcon
                    style={{ color: 'green', width: '40px', height: '40px' }}
                  />
                  {file.name}
                </>
              )}
            </Box>
          </Box>
          <Box marginTop={'30px'} display='flex'>
            <Box width={'30px'} marginRight={'10px'}>
              <H2>2.</H2>
            </Box>
            <Box width={'170px'}>
              <ButtonIconLeft
                onClick={() => {
                  importCustomer({ variables: { input: file } }).then(
                    (result) => {
                      if (result?.data.importCustomers) {
                        refetch();
                      }
                    }
                  );
                }}
                icon={<FileCopyIcon />}
                size='small'
                isDisabled={loading}
              >
                Speichern
              </ButtonIconLeft>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default ImportModal;
