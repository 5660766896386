import { gql } from '@apollo/client';
const getDataForConfirmation = gql`
  query ($input: inputDataForConfirmation) {
    getDataForConfirmation(input: $input) {
      agentPhoto
      agentName
      agentSurname
      agentIs_male
      agentContactEmail
      agentContactPhone
      salutation
      customerName
      customerSurname
      customerIs_male
    }
  }
`;

export default getDataForConfirmation;
