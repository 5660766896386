import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import ThemeOption from '../utilites/config/themeOption';
import LoadingOverlay from '../components/blocks/LoadingOverlay';
import Header from '../components/blocks/Header';
import { Box, Grid } from '@material-ui/core';
import BrandPage from '../components/blocks/BrandPage';
import MenuCard from '../components/UI/MenuCard';
import { cardsForUser, cardsForAdminMenu } from '../cardList';
import { CardForMenu } from '../interfaces/MenuCard.iterface';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'block',
    maxWidth: '1200px',
    margin: '40px auto',
    boxSizing: 'border-box',
  },
}));
interface Props {
  isRedactor: boolean;
}
const StartPage = ({ isRedactor }: Props) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={ThemeOption}>
      <LoadingOverlay show={false} />
      <Header isRedactor={isRedactor} />
      <Box className={classes.content}>
        <BrandPage pageName={'Willkommen'} />
        <Grid container spacing={2}>
          {isRedactor
            ? cardsForAdminMenu.map((cardItem: CardForMenu, id) => {
                return (
                  <Grid item key={id}>
                    <MenuCard
                      icon={cardItem.icon}
                      title={cardItem.title}
                      description={cardItem.description}
                      link={cardItem.link}
                    />
                  </Grid>
                );
              })
            : cardsForUser.map((cardItem: CardForMenu, id) => {
                return (
                  <Grid item key={id}>
                    <MenuCard
                      icon={cardItem.icon}
                      title={cardItem.title}
                      description={cardItem.description}
                      link={cardItem.link}
                    />
                  </Grid>
                );
              })}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default StartPage;
