import React, { useState, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import ThemeOption from '../../utilites/config/themeOption';
import Button from '@material-ui/core/Button';
import { AgentThumbnailUrl } from '../../utilites/config/config';
import Modal from '@material-ui/core/Modal';
import Cropper from 'react-easy-crop';
import avatar_placeholder from '../../static/avatar_placeholder.png';
import ImgPlaceholder from '../../static/ImgPlaceholder.jpg';
import uploadCroppedImage from '../../utilites/uploadCroppedImage';
import PrimaryButtonMedium from '../UI/PrimaryButtonMedium';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  ContentBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  Avatar: {
    display: 'block',
    width: '80px',
    height: '80px',
    borderRadius: '80px',
    marginRight: '20px',
    objectFit: 'cover',
  },
  icon: {
    display: 'block',
    fontSize: '32px',
    cursor: 'pointer',
    color: '#ffffff',
    '&:hover': { color: '#54BFD0' },
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  ModalContent: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    width: '450px',
    height: '550px',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%,-50%)`,
    background: 'white',
  },
  crop: {
    backgroundImage: `url(${ImgPlaceholder})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    marginTop: '30px',
    position: 'relative',
    height: '400px',
    width: '100%',
    background: 'black',
    display: 'flex',
    alignItems: 'center',
  },
  buttons: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

export default function UserAvatar({
  agentID,
  changeCurrentImageAgent,
  currentImageAgent,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [inputImg, setInputImg] = useState('');
  const [ImgBlob, setImgBlob] = useState('');
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const [onUpload, setOnUpload] = useState(false);
  const [imgToSmall, setImgToSmall] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        setImgBlob(file);

        setInputImg(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  function BlockImage({ currentImageAgent }) {
    let isAvatarBlob = ImgBlob instanceof Blob;
    if (isAvatarBlob) {
      return <img className={classes.Avatar} src={inputImg} alt='Avatar' />;
    } else {
      return (
        <img
          className={classes.Avatar}
          src={
            currentImageAgent
              ? AgentThumbnailUrl + currentImageAgent
              : avatar_placeholder
          }
          alt='Avatar'
        />
      );
    }
  }
  return (
    <ThemeProvider theme={ThemeOption}>
      <Modal open={open} onClose={handleClose}>
        <Box className={classes.ModalContent}>
          <input
            type='file'
            accept='image/x-png,image/jpeg'
            onChange={onInputChange}
            hidden={true}
            name='imageUpload'
            id='imageUpload'
          />
          <PrimaryButtonMedium
            onClick={(e) => {
              e.preventDefault();
              document.getElementById('imageUpload').click();
              setImgToSmall(false);
            }}
          >
            Bild wählen
          </PrimaryButtonMedium>
          <Box className={classes.crop}>
            <Cropper
              image={inputImg}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              onMediaLoaded={(mediaSize) => {
                if (mediaSize.height <= 240 || mediaSize.width <= 240) {
                  setImgToSmall(true);
                }
              }}
            />
          </Box>
          {onUpload && (
            <Box className={classes.buttons}>
              Bitte Warten....
              <CircularProgress />
            </Box>
          )}
          {imgToSmall && (
            <Typography color='error' align='center'>
              Bild ist zu klein
            </Typography>
          )}
          {!imgToSmall && (
            <Typography color='textSecondary' align='center'>
              Minimale Bildgröße 240 x 240
            </Typography>
          )}
          {!onUpload && (
            <Box className={classes.buttons}>
              <PrimaryButtonMedium
                disabled={imgToSmall}
                onClick={async (e) => {
                  setOnUpload(true);
                  await uploadCroppedImage(
                    ImgBlob,
                    croppedAreaPixels.x,
                    croppedAreaPixels.y,
                    croppedAreaPixels.width,
                    croppedAreaPixels.height,
                    agentID
                  ).then((result) => {
                    setOpen(false);
                    setInputImg('');
                    setImgBlob('');
                    setOnUpload(false);
                    changeCurrentImageAgent('');
                  });
                }}
              >
                Speichern
              </PrimaryButtonMedium>
              <PrimaryButtonMedium
                onClick={() => {
                  setOpen(false);
                  setInputImg('');
                  setImgBlob('');
                  setImgToSmall(false);
                }}
              >
                Abbrechen
              </PrimaryButtonMedium>
            </Box>
          )}
        </Box>
      </Modal>
      <Box className={classes.ContentBox}>
        <BlockImage currentImageAgent={currentImageAgent} />
        <div className={classes.root}>
          <Button
            variant='outlined'
            color='secondary'
            component='span'
            onClick={handleOpen}
          >
            Foto ändern
          </Button>
        </div>
      </Box>
    </ThemeProvider>
  );
}
