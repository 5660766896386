import { Component } from 'react';

class LogOut extends Component {
  componentDidMount() {
    // console.log('remove Token');
    localStorage.removeItem('token');
    window.location = '/mypage/login';
  }
  render() {
    return null;
  }
}

export default LogOut;
