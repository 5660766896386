import React from "react";
import Select from "react-select";
import styled from "styled-components";

const BoxSelect = styled.div`
  width: 100%;
  position: relative;
`;

const Label = styled.div`
  position: absolute;
  left: 16px;
  top: 6px;
  font-size: 12px;
  color: ${(props) => {
    if (props.error) {
      return "#f44336";
    } else {
      return "#828282";
    }
  }};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    Roboto;
  font-weight: 400;
  line-height: 1;
  z-index: 101;
`;

const HelperText = styled.div`
  font-size: 12px;
  padding-left: 16px;
  padding-top: 3px;
  color: ${(props) => {
    if (props.error) {
      return "#f44336";
    } else {
      return "#828282";
    }
  }};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    Roboto;
  font-weight: 400;
  line-height: 1;
  z-index: 101;
`;

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided, state) => {
    console.log("state select", state);
    return {
      ...provided,
      marginTop: 0,
      marginBottom: 0,
      borderRadius: 0,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 12,
      paddingBottom: 8,
      boxShadow: 0,
      background: "#e8e8e8",
      border: "1px solid #e8e8e8",
      borderBottom: state.selectProps.isError
        ? "2px solid #f44336"
        : "1px solid #999999",
      "&:hover": { borderBottom: "1px solid #999999" },
      "&:active": { borderBottom: "1px solid #999999" },
      "&:focus": { borderBottom: "1px solid #999999" },
    };
  },
  menu: (provided, state) => ({
    ...provided,
    background: "#ffffff",
    padding: 15,
    borderRadius: 0,
    zIndex: 100,
    border: "1px solid #CECECE",
    boxShadow: "0px 4px 8px 0px rgba(34, 60, 80, 0.2)",
    "&:hover": { border: "1px solid #CECECE" },
    "&:active": { border: "1px solid #CECECE" },
    "&:focus": { border: "1px solid #CECECE", borderColor: "#CECECE" },
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #CECECE",
    background: state.isSelected ? "#CECECE" : "#FFFFFF",
    color: state.isSelected ? "#383838" : "#383838",
    "&:last-child": { borderBottom: "0px" },
    "&:hover": { background: "#CECECE" },
    "&:active": { background: "#CECECE" },
    "&:focus": { background: "#CECECE" },
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      ...provided,
      opacity,
      transition,
    };
  },

  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontWeight: 400,
    color: "#383838",
    background: "#CECECE",
  }),

  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#383838",
    background: "#CECECE",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#CECECE",
      color: "#383838",
    },
  }),
};

export default function ReactSelect({
  disabled,
  value,
  listValue,
  changeData,
  isMulti = false,
  error,
  label = "Label",
  helperText = "Helper text",
}) {
  const handleChange = (selectedOption) => {
    console.log("Change select", selectedOption);
    changeData(selectedOption);
  };

  return (
    <BoxSelect>
      <Label error={error}>{label}</Label>
      <Select
        isMulti={isMulti}
        styles={customStyles}
        value={value}
        onChange={handleChange}
        defaultValue={value}
        options={listValue}
        isDisabled={disabled}
        isError={error}
      />
      <HelperText error={error}>{helperText}</HelperText>
    </BoxSelect>
  );
}
