import React from 'react';
import styled from 'styled-components';

const HeadingFive = styled.h5`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => {
    if (props.color) {
      return props.color;
    } else {
      return '#383838';
    }
  }};
  margin: 0;
`;

export default function Heading5({ children, color }) {
  return <HeadingFive color={color}>{children}</HeadingFive>;
}
