import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Header from '../components/blocks/Header';
import BrandPage from '../components/blocks/BrandPage';
import UserAvatar from '../components/blocks/UserAvatar';
import AlertMessage from '../components/UI/AlertMessage';
import Typography from '@material-ui/core/Typography';
import ThemeOption from '../utilites/config/themeOption';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Link from '@material-ui/core/Link';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PrimaryButtonMedium from '../components/UI/PrimaryButtonMedium';
import ArticleCard from '../components/blocks/ArticleCard';
import ListTourTheme from '../components/blocks/ListTourTheme';
import getDraftStorys from '../utilites/queryBodies/getDraftStorys';
import getPublishedStorys from '../utilites/queryBodies/getPublishedStorys';
import getRejectStorys from '../utilites/queryBodies/getRejectStorys';
import getExaminationStorys from '../utilites/queryBodies/getExaminationStorys';
import getSite from '../utilites/queryBodies/getSite';
import getAdvertisingTheme from '../utilites/queryBodies/getAdvertisingTheme';
import getTourThemes from '../utilites/queryBodies/getTourThemes';
import updateSite from '../utilites/queryBodies/updateSite';
import setSiteToDisable from '../utilites/queryBodies/setSiteToDisable';
import setSiteToAktive from '../utilites/queryBodies/setSiteToAktive';
import requestToServer from '../utilites/requestToServer';
import { minThemesUser } from '../utilites/config/config';
import LoadingOverlay from '../components/blocks/LoadingOverlay';
import MyModal from '../components/UI/MyModal';
import ButtonBack from '../components/UI/ButtonBack';
import siteInitValue from '../types/initValues/siteInitValue';
import SiteValid from '../types/validSchemas/Site.valid';
import { parsePhoneNumber, ParseError } from 'libphonenumber-js';
import { COUNTRYCODE } from '../utilites/countrys';

const useStyles = makeStyles((theme) => ({
  customSwitch: {
    color: theme.palette.primary.main,
  },
  content: {
    display: 'block',
    maxWidth: '1200px',
    margin: '40px auto',
    boxSizing: 'border-box',
  },

  icon: {
    display: 'block',
    fontSize: '32px',
    cursor: 'pointer',
    color: '#ffffff',
    '&:hover': { color: '#54BFD0' },
  },
  WebSiteBox: {
    margin: '20px 10px 20px 10px',
  },
  ItemBox: {
    width: '100%',
    display: 'block',
    margin: '20px 10px',
  },
  RowBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginBottom: '10px',
    },
  },
  TabBox: {
    marginTop: '6px',
  },
  Input: {
    width: '90%',
  },
  InputPhoneField: {
    width: '70%',
  },
  caption: {
    marginTop: '20px',
    marginBottom: '10px',
    color: '#333333',
    fontSize: '28px',
    lineHeight: '32px',
    fontFamily: "'Poppins', sans-serif",
  },
  label: {
    color: '#333333',
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: "'Poppins', sans-serif",
  },
  tabCaption: {
    color: '#333333',
    fontSize: '18px',
    lineHeight: '24px',
    fontFamily: "'Poppins', sans-serif",
  },
  checkBox: {
    display: 'block',
    fontFamily: "'Poppins', sans-serif",
    fontSize: '16px',
    lineHeight: '24px',
  },
  formControl: {
    width: '100%',
  },
  textImageStatus: {
    paddingTop: '8px',
    color: '#D93D23',
    display: (props) => {
      if (props.counter !== 0) {
        return 'block';
      } else {
        return 'none';
      }
    },
  },
}));

function getBranchOfficeURL(id) {
  const branchOffice = [
    'mein-urlaubsglueck.de',
    'mytravelista.de',
    'aer-mobile.de',
    'my-travelexpert.de',
  ];
  return branchOffice[id - 1];
}

export default function ArticlesListPage({ isRedactor }) {
  let userInfo = JSON.parse(localStorage.getItem('user'));
  let history = useHistory();
  const classes = useStyles();
  const [tourTheme, setTourTheme] = useState(['']);
  const [isShowPublishedStorys, setIsShowPublishedStorys] = useState(false);
  const [currentImageAgent, setCurrentImageAgent] = useState('');
  const [typeMessage, setTypeMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isAlertMessage, setIsAlertMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleChangeIsShowPublished = () => {
    setIsShowPublishedStorys(!isShowPublishedStorys);
  };
  const [listArticlesPublished, setListArticlesPublished] = useState([]);
  const [listArticlesDraft, setListArticlesDraft] = useState([]);
  const [listArticlesExamination, setListArticlesExamination] = useState([]);
  const [listArticlesReject, setListArticlesReject] = useState([]);
  const [listTourThemes, setListTourThemes] = useState([]);
  const [counterTheme, setCounterTheme] = useState(minThemesUser);
  const [openModal, setOpenModal] = useState(false);
  const [site, setSite] = useState(siteInitValue);
  const [countryCode, setCountryCode] = useState('DE');

  async function getListsSory() {
    const arrayRequest = [
      getDraftStorys,
      getPublishedStorys,
      getRejectStorys,
      getExaminationStorys,
    ];
    requestToServer('query', arrayRequest, {})
      .then((result) => {
        setListArticlesPublished(result.getPublishedStorys);
        setListArticlesDraft(result.getDraftStorys);
        setListArticlesReject(result.getRejectStorys);
        setListArticlesExamination(result.getExaminationStorys);
      })
      .catch((error) => {
        console.error(
          'There is requestToServer data Error in this AllParametrs request:',
          error
        );
        return [];
      });
  }

  useEffect(() => {
    function getData() {
      const arrayRequest = [
        getSite,
        getDraftStorys,
        getPublishedStorys,
        getRejectStorys,
        getExaminationStorys,
        getTourThemes,
        getAdvertisingTheme,
      ];
      requestToServer('query', arrayRequest, {})
        .then((result) => {
          if (result.getPublishedStorys) {
            setListArticlesPublished(result.getPublishedStorys);
          }
          if (result.getDraftStorys) {
            setListArticlesDraft(result.getDraftStorys);
          }
          if (result.getRejectStorys) {
            setListArticlesReject(result.getRejectStorys);
          }
          if (result.getExaminationStorys) {
            setListArticlesExamination(result.getExaminationStorys);
          }
          if (result.getTourThemes) {
            setListTourThemes(result.getTourThemes);
          }
          if (result.getSite?.tourTheme) {
            setTourTheme(result.getSite.tourTheme);
            setCounterTheme(minThemesUser - result.getSite.tourTheme.length);
          }
          if (result.getSite?.agent_image) {
            setCurrentImageAgent(result.getSite.agent_image);
          }
          if (result?.getSite) {
            setSite(result.getSite);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(
            'There is requestToServer data Error in this AllParametrs request:',
            error
          );
          return [];
        });
    }
    getData();
  }, [currentImageAgent]);

  const hendleCreateORupdateSite = (values) => {
    setIsLoading(true);
    const id = values._id.toString();
    const listTourThemeId = tourTheme.map((item) => item._id.toString());
    const input = {
      tourTheme: listTourThemeId,
      phone: values.phone,
      insta_link: values.insta_link,
      facebook_link: values.facebook_link,
      isWhatsApp: values.isWhatsApp,
      aboutMe: values.aboutMe,
    };
    requestToServer('mutation', updateSite, { input, id })
      .then((result) => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(
          'There is requestToServer data Error in this AllParametrs request:',
          error
        );
        return [];
      });
  };

  const handleChangeIsPublishedWebsite = (Id, isActive) => {
    const id = Id.toString();
    if (isActive) {
      requestToServer('mutation', setSiteToAktive, { id })
        .then((result) => {
          if ('error' in result) {
            setTypeMessage('error');
            setAlertMessage('Error');
            setIsAlertMessage(true);
          } else {
            setTypeMessage('success');
            setAlertMessage('Website erfolgreich veröffentlicht');
            setIsAlertMessage(true);
          }
        })
        .catch((error) => {
          console.error(
            'There is requestToServer data Error in this AllParametrs request:',
            error
          );
          setTypeMessage('error');
          setAlertMessage('Error');
          setIsAlertMessage(true);
          return [];
        });
    } else {
      requestToServer('mutation', setSiteToDisable, { id })
        .then((result) => {
          if ('error' in result) {
            setTypeMessage('error');
            setAlertMessage('Error');
            setIsAlertMessage(true);
          } else {
            setTypeMessage('success');
            setAlertMessage('Website erfolgreich unveröffentlicht');
            setIsAlertMessage(true);
          }
        })
        .catch((error) => {
          console.error(
            'There is requestToServer data Error in this AllParametrs request:',
            error
          );
          setTypeMessage('error');
          setAlertMessage('Error');
          setIsAlertMessage(true);
          return [];
        });
    }
  };

  const changeTourTheme = (value) => {
    setTourTheme(value);
    setCounterTheme(minThemesUser - value.length);
  };
  const handleChangeIsAlertMessage = () => {
    setIsAlertMessage(false);
  };

  const TextFormStatus = (props) => {
    const classes = useStyles(props);
    return (
      <Typography className={classes.textImageStatus} component="span">
        {props.children}
      </Typography>
    );
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  function formatingPhone(phone, counrtyCode) {
    if (phone) {
      try {
        const formatetPhoneNumber = parsePhoneNumber(phone, counrtyCode);
        return formatetPhoneNumber.formatNational();
      } catch (parseError) {
        if (parseError instanceof ParseError) {
          console.error(`parseError`, parseError.message);
        }
      }
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={site}
      onSubmit={(values) => {
        hendleCreateORupdateSite(values);
      }}
      validationSchema={SiteValid(countryCode)}
    >
      {(props) => {
        const {
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          errors,
          touched,
          isValid,
          setFieldValue,
        } = props;
        return (
          <ThemeProvider theme={ThemeOption}>
            <LoadingOverlay show={isLoading} />
            <Header isRedactor={isRedactor} />
            <MyModal openMod={openModal} closeMod={handleClose} />
            <Box className={classes.content}>
              <BrandPage pageName={'Homepage'} />
              <ButtonBack link={'/mypage'} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Box className={classes.WebSiteBox}>
                    <Typography className={classes.caption} component="span">
                      {values.agent[0].name} {values.agent[0].surname}
                    </Typography>
                    <Typography className={classes.label} component="p">
                      <Link
                        href={
                          'https://' +
                          getBranchOfficeURL(userInfo.branch_office_id) +
                          values.route
                        }
                      >
                        https://
                        {getBranchOfficeURL(userInfo.branch_office_id) +
                          values.route}
                      </Link>
                    </Typography>

                    <FormControlLabel
                      control={
                        <Switch
                          name="is_aktive"
                          checked={values.is_aktive}
                          onChange={(event) => {
                            setFieldValue('is_aktive', event.target.checked);
                            handleChangeIsPublishedWebsite(
                              values._id,
                              event.target.checked
                            );
                          }}
                          disabled={tourTheme.length < minThemesUser}
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label="Website veröffentlicht"
                    />
                  </Box>
                  <Box className={classes.ItemBox}>
                    <UserAvatar
                      changeCurrentImageAgent={setCurrentImageAgent}
                      currentImageAgent={currentImageAgent}
                    />
                  </Box>
                  <Box className={classes.ItemBox}>
                    <PrimaryButtonMedium onClick={handleOpen}>
                      + Über mich Text hinzufügen
                    </PrimaryButtonMedium>
                  </Box>
                  <Box
                    className={classes.ItemBox}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      width: '90%',
                      gap: '10px',
                    }}
                  >
                    <FormControl variant="filled" style={{ width: '25%' }}>
                      <InputLabel>Land</InputLabel>
                      <Select
                        value={countryCode}
                        onChange={(event) => {
                          setCountryCode(event.target.value);
                        }}
                      >
                        {COUNTRYCODE.map((country) => {
                          return (
                            <MenuItem value={country.code}>
                              {country.code}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <TextField
                      className={classes.InputPhoneField}
                      id="Telefon"
                      variant="filled"
                      label="Telefon"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={(event) => {
                        handleBlur(event);
                        setFieldValue(
                          'phone',
                          formatingPhone(values.phone, countryCode)
                        );
                      }}
                      name="phone"
                      helperText={errors.phone && touched.phone && errors.phone}
                      error={Boolean(errors.phone && touched.phone) || false}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PhoneAndroidOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box className={classes.ItemBox}>
                    <TextField
                      className={classes.Input}
                      id="Instagram"
                      variant="filled"
                      label="Instagram"
                      value={values.insta_link}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="insta_link"
                      helperText={
                        (errors.insta_link &&
                          touched.insta_link &&
                          errors.insta_link) ||
                        'Fügen Sie Ihrer Seite im sozialen Netzwerk einen Link hinzu'
                      }
                      error={
                        Boolean(errors.insta_link && touched.insta_link) ||
                        false
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <InstagramIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box className={classes.ItemBox}>
                    <TextField
                      className={classes.Input}
                      id="Facebook"
                      variant="filled"
                      label="Facebook"
                      value={values.facebook_link}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="facebook_link"
                      helperText={
                        (errors.facebook_link &&
                          touched.facebook_link &&
                          errors.facebook_link) ||
                        'Fügen Sie Ihrer Seite im sozialen Netzwerk einen Link hinzu'
                      }
                      error={
                        Boolean(
                          errors.facebook_link && touched.facebook_link
                        ) || false
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FacebookIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box className={classes.ItemBox}>
                    <Typography className={classes.caption} component="span">
                      Kommunikationskanäle
                    </Typography>
                    <FormLabel component="legend">
                      Kommunikation über Messenger
                    </FormLabel>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      style={{ width: '60%' }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isWhatsApp"
                            checked={values.isWhatsApp}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            color="primary"
                          />
                        }
                        label="Whatsapp"
                      />
                    </Box>
                  </Box>
                  <Box className={classes.ItemBox}>
                    <Typography className={classes.caption} component="span">
                      Meine Themen
                    </Typography>
                    <ListTourTheme
                      listTourThemes={listTourThemes}
                      changeTourTheme={changeTourTheme}
                      tourTheme={tourTheme}
                    />
                  </Box>
                  <Box className={classes.ItemBox}>
                    <PrimaryButtonMedium
                      disabled={counterTheme !== 0 && isValid}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Änderungen speichern
                    </PrimaryButtonMedium>
                    <TextFormStatus counter={counterTheme}>
                      Wählen Sie 4 Themen aus
                    </TextFormStatus>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Box className={classes.RowBox}>
                    <Typography className={classes.caption} component="span">
                      Meine Artikel
                    </Typography>
                    <PrimaryButtonMedium
                      onClick={() => {
                        history.push('/mypage/add-article');
                      }}
                    >
                      + Artikel hinzufügen
                    </PrimaryButtonMedium>
                  </Box>
                  <Box className={classes.RowBox}></Box>
                  <Box className={classes.TabBox}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isShowPublishedStorys}
                          onChange={handleChangeIsShowPublished}
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label="Veröffentlichte Berichte"
                    />
                    {!isShowPublishedStorys && (
                      <div>
                        {listArticlesDraft.length > 0 &&
                          listArticlesDraft.map((item) => {
                            return (
                              <Box key={item._id} className={classes.ItemBox}>
                                <ArticleCard
                                  id={item._id}
                                  coverUrl={item.images[0]}
                                  data={item.updatedAt}
                                  title={item.title}
                                  status={item.status}
                                  getListsSory={getListsSory}
                                  modURL="/mypage/edit-article/"
                                />
                              </Box>
                            );
                          })}
                        {listArticlesExamination.length > 0 &&
                          listArticlesExamination.map((item) => {
                            return (
                              <Box key={item._id} className={classes.ItemBox}>
                                <ArticleCard
                                  id={item._id}
                                  coverUrl={item.images[0]}
                                  data={item.updatedAt}
                                  title={item.title}
                                  status={item.status}
                                  getListsSory={getListsSory}
                                  modURL="/mypage/edit-article/"
                                />
                              </Box>
                            );
                          })}
                        {listArticlesReject.length > 0 &&
                          listArticlesReject.map((item) => {
                            return (
                              <Box key={item._id} className={classes.ItemBox}>
                                <ArticleCard
                                  id={item._id}
                                  coverUrl={item.images[0]}
                                  data={item.updatedAt}
                                  title={item.title}
                                  status={item.status}
                                  getListsSory={getListsSory}
                                  modURL="/mypage/edit-article/"
                                />
                              </Box>
                            );
                          })}
                      </div>
                    )}
                    {isShowPublishedStorys && (
                      <div>
                        {listArticlesPublished.length > 0 &&
                          listArticlesPublished.map((item) => {
                            return (
                              <Box key={item._id} className={classes.ItemBox}>
                                <ArticleCard
                                  id={item._id}
                                  coverUrl={item.images[0]}
                                  data={item.updatedAt}
                                  title={item.title}
                                  status={item.status}
                                  getListsSory={getListsSory}
                                  isActive={item.is_aktive}
                                  modURL="/mypage/edit-published-article/"
                                />
                              </Box>
                            );
                          })}
                      </div>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <AlertMessage
              typeMessage={typeMessage}
              alertMessage={alertMessage}
              isAlertMessage={isAlertMessage}
              handleChangeIsAlertMessage={handleChangeIsAlertMessage}
            />
          </ThemeProvider>
        );
      }}
    </Formik>
  );
}
