import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  makeStyles,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  NativeSelect,
} from '@material-ui/core';
import { EmailOutline } from '@styled-icons/evaicons-outline/EmailOutline';
import { Phone } from '@styled-icons/bootstrap/Phone';
import { At } from '@styled-icons/boxicons-regular/At';
import { Trash } from '@styled-icons/boxicons-regular/Trash';
import { Speakerphone } from '@styled-icons/heroicons-outline/Speakerphone';
import { Error as ErrorIcon } from '@styled-icons/boxicons-regular/Error';
import ButtonTextOnly from '../UI/ButtonTextOnly';
import ButtonOutlinedTextOnly from '../UI/ButtonOutlinedTextOnly';
import ButtonIconLeft from '../UI/ButtonIconLeft';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import CustomerInterface from '../../types/Customer.types';
import CustomerUpdateQuery from '../../utilites/Apollo/mutation/updateCustomer';
import DeleteCustomerQuery from '../../utilites/Apollo/mutation/deleteCustomer';
import CreateCustomerQuery from '../../utilites/Apollo/mutation/createCustomer';
import SendingRequestQuery from '../../utilites/Apollo/query/sendingRequest';
import H6 from '../UI/Typography/Heading6';
import ThemeOption from '../../utilites/config/themeOption';

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
  },
  communicationItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    height: '36px',
  },
}));

interface ComponentInput {
  customer: CustomerInterface;
  triggerReload: Function;
  resetCustomer: Function;
  setCustomer: Function;
}

const formatDate = (date: string) => {
  if (date === '' || !date) return '';
  const newDate = date.split('T');
  const time = newDate[1].split('.')[0].split(',')[0];
  return `${newDate[0]}  ${time}`;
};

interface Init {
  _id: string | undefined;
  is_male: number | boolean;
  salutation: number | boolean;
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  notice: string | undefined;
  regularCustomer: number | boolean | undefined;
}

export default function EditCustomer({
  customer,
  triggerReload,
  resetCustomer,
  setCustomer,
}: ComponentInput) {
  const classes = useStyles();
  const [isNew, setIsNew] = useState(true);
  const [sended, setSended] = useState(false);
  const [lastRequestDate, setLastRequestDate] = useState<String | null>(null);

  useEffect(() => {
    if (Object.keys(customer).length === 0) {
      setIsNew(true);
    } else {
      setIsNew(false);
    }

    setSended(false);
    setLastRequestDate(null);
  }, [customer]);

  const [updateCustomer, { data, loading: updateLoading, error: updateError }] =
    useMutation(CustomerUpdateQuery, {
      onError: () => {},
    });

  const [
    deleteCustomer,
    { data: deleteData, loading: deleteLoading, error: deleteError },
  ] = useMutation(DeleteCustomerQuery, {
    onError: () => {},
  });
  const [
    createCustomer,
    { data: createData, loading: createLoading, error: createError },
  ] = useMutation(CreateCustomerQuery, {
    onError: () => {},
  });
  const [
    sendingRequestToCustomer,
    { data: requestData, loading: requestLoading, error: requestError },
  ] = useMutation(SendingRequestQuery, {
    onError: (err) => {
      setSended(true);
    },
    onCompleted: () => {
      setSended(true);
      setLastRequestDate(
        new Date().toISOString().split('.')[0].replace('T', ' ')
      );
    },
  });
  useEffect(() => {
    setTimeout(() => {
      triggerReload();
    }, 1000);
  }, [deleteLoading, updateLoading, createLoading]);

  const {
    _id,
    salutation,
    name,
    surname,
    email,
    notice,
    mailing,
    phoneCalls,
    digitalCommunication,
    newsletter,
    dateAccept,
    is_male,
    regularCustomer,
  } = customer;

  const InitValue: Init = {
    _id: _id,
    is_male: is_male ? 1 : 0,
    salutation: salutation ? 1 : 0,
    name: name || '',
    surname: surname || '',
    email: email || '',
    notice: notice || '',
    regularCustomer: regularCustomer ? 1 : 0,
  };

  return (
    <Box className={classes.content} width={570}>
      <Box className={classes.deleteButton}>
        {!isNew && (
          <ButtonIconLeft
            icon={<Trash size={20} />}
            size='small'
            style={{ background: 'red' }}
            onClick={() => {
              deleteCustomer({ variables: { input: { idArray: [_id] } } });
              resetCustomer();
              setSended(false);
            }}
            isLoading={deleteLoading}
          >
            Kunden Löschen
          </ButtonIconLeft>
        )}
      </Box>
      <Formik
        enableReinitialize
        initialValues={InitValue}
        onSubmit={(values, { setSubmitting }) => {
          if (Boolean(Number(values.is_male))) {
            values.is_male = true;
          } else {
            values.is_male = false;
          }
          if (Boolean(Number(values.salutation))) {
            values.salutation = true;
          } else {
            values.salutation = false;
          }
          if (Boolean(Number(values.regularCustomer))) {
            values.regularCustomer = true;
          } else {
            values.regularCustomer = false;
          }
          setCustomer(Object.assign(customer, customer, values));

          if (isNew) {
            createCustomer({ variables: { input: values } });
            resetCustomer();
          } else {
            updateCustomer({ variables: { input: values } });
          }

          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          salutation: Yup.string().required('Pflichtfeld'),
          name: Yup.string().required('Pflichtfeld'),
          surname: Yup.string().required('Pflichtfeld'),
          email: Yup.string().email('ungültige Email').required('Pflichtfeld'),
          notice: Yup.string(),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            dirty,
          } = props;

          return (
            <Box width={570}>
              <form onSubmit={handleSubmit}>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '570px',
                  }}
                >
                  <FormControl style={{ flexGrow: 1, margin: '1%' }}>
                    <InputLabel htmlFor='uncontrolled-native'>
                      Anrede
                    </InputLabel>
                    <NativeSelect
                      value={values.is_male}
                      name='is_male'
                      onChange={handleChange}
                    >
                      <option value={1}>Herr</option>
                      <option value={0}>Frau</option>
                    </NativeSelect>
                  </FormControl>
                  <FormControl style={{ flexGrow: 1, margin: '1%' }}>
                    <InputLabel htmlFor='uncontrolled-native'>
                      Ansprache
                    </InputLabel>
                    <NativeSelect
                      value={values.salutation}
                      name='salutation'
                      onChange={handleChange}
                    >
                      <option value={1}>Sie</option>
                      <option value={0}>Du</option>
                    </NativeSelect>
                  </FormControl>
                  <FormControl style={{ flexGrow: 1, margin: '1%' }}>
                    <InputLabel htmlFor='uncontrolled-native'>
                      Kundenstatus
                    </InputLabel>
                    <NativeSelect
                      //value muss noch an das neue Object angepasst werden
                      value={values.regularCustomer}
                      name='regularCustomer'
                      onChange={handleChange}
                    >
                      <option value={1}>Bestandskunde</option>
                      <option value={0}>Neukunde</option>
                    </NativeSelect>
                  </FormControl>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '570px',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      marginRight: '50px',
                    }}
                  >
                    <TextField
                      variant='filled'
                      error={Boolean(errors.name && touched.name) || false}
                      label='Vorname'
                      name='name'
                      value={values.name ?? ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={errors.name && touched.name && errors.name}
                      margin='normal'
                    />
                    <TextField
                      variant='filled'
                      error={Boolean(errors.email && touched.email) || false}
                      label='E-Mail'
                      name='email'
                      value={values.email ?? ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={errors.email && touched.email && errors.email}
                      margin='normal'
                    />
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <TextField
                      variant='filled'
                      error={
                        Boolean(errors.surname && touched.surname) || false
                      }
                      label='Nachname'
                      name='surname'
                      value={values.surname ?? ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.surname && touched.surname && errors.surname
                      }
                      margin='normal'
                    />

                    <TextField
                      disabled
                      variant='filled'
                      error={Boolean(errors.notice && touched.notice) || false}
                      label='Akzeptiert'
                      name='accept'
                      value={dateAccept ? formatDate(dateAccept) : ''}
                      margin='normal'
                    />
                  </Box>
                </Box>
                <TextField
                  fullWidth
                  variant='filled'
                  error={Boolean(errors.notice && touched.notice) || false}
                  label='Notizen'
                  name='notice'
                  value={values.notice}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.notice && touched.notice && errors.notice}
                  margin='normal'
                />

                <Box marginTop={'32px'}>
                  <ButtonOutlinedTextOnly
                    style={{
                      // @ts-ignore
                      borderRadius: ThemeOption.btn.borderRadius,
                    }}
                    size={'small'}
                    type='submit'
                    isDisabled={isSubmitting || !dirty}
                    isLoading={updateLoading || createLoading}
                  >
                    {isNew ? 'neuen Kunden anlegen' : 'Änderung speichern'}
                  </ButtonOutlinedTextOnly>
                  {updateError && (
                    <>
                      <ErrorIcon color='red' size={20} />
                      Es ist eine Fehler aufgetreten
                    </>
                  )}
                </Box>
              </form>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  width: '100%',
                  marginTop: '32px',
                }}
              >
                <Box className={classes.communicationItem}>
                  <EmailOutline
                    size={'25px'}
                    style={{ color: ThemeOption.palette.primary.main }}
                  />
                  <FormControlLabel
                    control={<Checkbox color='primary' />}
                    label='Mail'
                    labelPlacement='top'
                    checked={mailing ?? false}
                    disabled={true}
                  />
                </Box>
                <Box className={classes.communicationItem}>
                  <Phone
                    size={'25px'}
                    style={{ color: ThemeOption.palette.primary.main }}
                  />
                  <FormControlLabel
                    control={<Checkbox color='primary' />}
                    label='Phone'
                    labelPlacement='top'
                    checked={phoneCalls ?? false}
                    disabled={true}
                  />
                </Box>
                <Box className={classes.communicationItem}>
                  <At
                    size={'25px'}
                    style={{ color: ThemeOption.palette.primary.main }}
                  />
                  <FormControlLabel
                    control={<Checkbox color='primary' />}
                    label='WhatsApp, Facebook, Instagram'
                    labelPlacement='top'
                    checked={digitalCommunication ?? false}
                    disabled={true}
                  />
                </Box>
                <Box className={classes.communicationItem}>
                  <Speakerphone
                    size={'25px'}
                    style={{ color: ThemeOption.palette.primary.main }}
                  />
                  <FormControlLabel
                    control={<Checkbox color='primary' />}
                    label='News'
                    labelPlacement='top'
                    checked={newsletter ?? false}
                    disabled={true}
                  />
                </Box>
              </Box>
              {!isNew && (
                <>
                  <Box style={{ marginBottom: '10px' }}>
                    <H6>
                      {!customer.lastRequest &&
                        lastRequestDate &&
                        `letzte Anfrage: ${lastRequestDate}`}
                      {!customer.lastRequest &&
                        !lastRequestDate &&
                        `noch keine Anfrage versendet`}
                      {customer.lastRequest &&
                        `letzte Anfrage: ${customer.lastRequest}`}
                    </H6>
                  </Box>
                  {customer.lastRequest && (
                    <ButtonTextOnly
                      // @ts-ignore
                      style={{ borderRadius: ThemeOption.btn.borderRadius }}
                      isLoading={requestLoading}
                      isDisabled={dirty}
                      onClick={() => {
                        sendingRequestToCustomer({
                          variables: { input: { email: customer.email } },
                        });
                      }}
                    >
                      'Anfrage erneut senden'
                    </ButtonTextOnly>
                  )}
                  {lastRequestDate && (
                    <ButtonTextOnly
                      // @ts-ignore
                      style={{ borderRadius: ThemeOption.btn.borderRadius }}
                      isLoading={requestLoading}
                      onClick={() => {
                        sendingRequestToCustomer({
                          variables: { input: { email: customer.email } },
                        });
                      }}
                    >
                      'Anfrage erneut senden'
                    </ButtonTextOnly>
                  )}
                  {!customer.lastRequest && !lastRequestDate && (
                    <ButtonTextOnly
                      // @ts-ignore
                      style={{ borderRadius: ThemeOption.btn.borderRadius }}
                      isLoading={requestLoading}
                      isDisabled={dirty}
                      onClick={() => {
                        sendingRequestToCustomer({
                          variables: { input: { email: customer.email } },
                        });
                      }}
                    >
                      'Anfrage senden'
                    </ButtonTextOnly>
                  )}

                  {sended &&
                  requestError?.graphQLErrors[0]?.extensions?.code === 2604 ? (
                    <Box style={{ marginTop: '15px' }}>
                      <H6 color='red'>
                        Anfragen dürfen nur alle 10 min versendet werden
                      </H6>
                    </Box>
                  ) : (
                    ''
                  )}
                  {sended && !requestError && (
                    <Box style={{ marginTop: '15px' }}>
                      <H6 color='green'>Anfragen versendet</H6>
                    </Box>
                  )}
                </>
              )}
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
}
