import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import ThemeOption from "../../utilites/config/themeOption";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import SecondaryButtonMedium from "../../components/UI/SecondaryButtonMedium";
import ErrorButtonMedium from "../../components/UI/ErrorButtonMedium";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AlertMessage from "../../components/UI/AlertMessage";
import { imageServerUrl } from "../../utilites/config/config";
import setStoryToEXAMINATION from "../../utilites/queryBodies/setStoryToEXAMINATION";
import setStoryToDRAFT from "../../utilites/queryBodies/setStoryToDRAFT";
import deleteStory from "../../utilites/queryBodies/deleteStory";
import deletePublishedStory from "../../utilites/queryBodies/deletePublishedStory";
import aktivedORdiactivatePublishedStory from "../../utilites/queryBodies/aktivedORdiactivatePublishedStory";
import createORupdateStory from "../../utilites/queryBodies/createORupdateStory";
import requestToServer from "../../utilites/requestToServer";

const useStyles = makeStyles((theme) => ({
  CardBox: {
    display: "flex",
    marginBottom: "20px",
    background:
      "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
    boxShadow: "0px 4px 12px rgba(23, 66, 99, 0.15)",
    maxWidth: "960px",
    boxSizing: "border-box",
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  LeftBox: {
    width: "150px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  textOption: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "18px",
    lineHeight: "20px",
    color: "#BDBDBD",
  },
  authorTextOption: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "14px",
    lineHeight: "15px",
    color: "#BDBDBD",
  },
  AuthorBox: {
    display: "flex",
    flexDirection: "column",
  },
  RightBox: {
    width: "100%",
    margin: "20px 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  TitleCard: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "28px",
    lineHeight: "32px",
    color: "#333333",
    marginBottom: "10px",
    cursor: (props) => {
      if (props.isCursorPointer) {
        return "pointer";
      } else {
        return "default";
      }
    },
  },
  OptionCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  statusCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "10px",
    },
  },
  editCard: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      justifyContent: "space-between",
      marginTop: "10px",
    },
  },
  icon: {
    display: "inline-block",
    fontSize: "24px",
    color: "#123E60",
    margin: "0 5px",
    cursor: "pointer",
  },
  editIconCard: {
    cursor: "pointer",
  },
  textStatus: {
    fontSize: "18px",
    color: (props) => {
      if (props.status === "PUBLISHED") {
        return "#54BFD0";
      } else if (props.status === "EXAMINATION") {
        return "#FBD400";
      } else if (props.status === "DRAFT") {
        return "#EC8225";
      } else if (props.status === "REJECT") {
        return "#D93D23";
      } else {
        return "#000000";
      }
    },
  },
  imageBox: {
    width: "100%",
    height: "80px",
  },
}));

export default function ArticleCard({
  id = "5fc75fc6671f5b7b71461ee5",
  data = "20.20.2020",
  title = "Test",
  agent = {},
  status = "DRAFT",
  coverUrl = "",
  getListsSory,
  isActive,
  isRedactor,
  modURL,
}) {
  const classes = useStyles();
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [stateSwitcher, setStateSwitcher] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertMessage, setIsAlertMessage] = useState(false);

  useEffect(() => {
    setStateSwitcher(isActive);
  }, [isActive]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSwitcher = (event) => {
    setStateSwitcher(event.target.checked);
    const aktive = event.target.checked;
    console.log("Click aktivedORdiactivatePublishedStory");
    requestToServer("mutation", aktivedORdiactivatePublishedStory, {
      id,
      aktive,
    })
      .then((result) => {
        console.log("Response", result);
        getListsSory();
      })
      .catch((error) => {
        console.log(
          "There is requestToServer data Error in this AllParametrs request:",
          error
        );
        return [];
      });
  };

  const handleDeleteStory = () => {
    setOpen(false);
    console.log("Click deleteStory");
    requestToServer("mutation", deleteStory, { id })
      .then((result) => {
        console.log("Response", result);
        getListsSory();
      })
      .catch((error) => {
        console.log(
          "There is requestToServer data Error in this AllParametrs request:",
          error
        );
        return [];
      });
  };
  const handleDeletePublishedStory = () => {
    setOpen(false);
    console.log("Click deletePublishedStory");
    requestToServer("mutation", deletePublishedStory, { id })
      .then((result) => {
        console.log("Response", result);
        getListsSory();
      })
      .catch((error) => {
        console.log(
          "There is requestToServer data Error in this AllParametrs request:",
          error
        );
        return [];
      });
  };

  const handlerDuplicateStory = () => {
    console.log("Click handlerDuplicateStory");
    const input = { title: title };
    const published_counterpart_id = id;
    requestToServer("mutation", createORupdateStory, {
      input,
      published_counterpart_id,
    })
      .then((result) => {
        console.log("Response", result);
        if (result.error) {
          if (result.error[0].includes("DRAFT")) {
            setTypeMessage("error");
            setAlertMessage("Dieser Artikel ist bereits in Entwürfen!");
            setIsAlertMessage(true);
          }
        } else {
          history.push(
            "/mypage/edit-article/" + result.createORupdateStory._id
          );
        }
      })
      .catch((error) => {
        console.log(
          "There is requestToServer data Error in this AllParametrs request:",
          error
        );
        return [];
      });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSetStoryToEXAMINATION = () => {
    console.log("Click handleSetStoryToEXAMINATION");
    requestToServer("mutation", setStoryToEXAMINATION, { id })
      .then((result) => {
        console.log("Response", result);
        getListsSory();
      })
      .catch((error) => {
        console.log(
          "There is requestToServer data Error in this AllParametrs request:",
          error
        );
        return [];
      });
  };

  const handleSetStoryToDRAFT = () => {
    console.log("Click handleSetStoryToDRAFT");
    requestToServer("mutation", setStoryToDRAFT, { id })
      .then((result) => {
        console.log("Response", result);
        getListsSory();
      })
      .catch((error) => {
        console.log(
          "There is requestToServer data Error in this AllParametrs request:",
          error
        );
        return [];
      });
  };

  const handleChangeIsAlertMessage = () => {
    setIsAlertMessage(false);
  };

  const TextStatus = (props) => {
    const classes = useStyles(props);
    return (
      <Typography className={classes.textStatus} component="span">
        {props.children}
      </Typography>
    );
  };

  const TitleText = (props) => {
    const classes = useStyles(props);
    return (
      <Typography
        className={classes.TitleCard}
        component="h1"
        onClick={() => {
          console.log("Click");
          // if (status !== 'EXAMINATION') {
          history.push(modURL + id);
          // }
        }}
      >
        {props.children}
      </Typography>
    );
  };

  return (
    <ThemeProvider theme={ThemeOption}>
      <Box className={classes.CardBox}>
        <Box className={classes.LeftBox}>
          <Typography className={classes.textOption} component="span">
            {data}
          </Typography>
          <img
            className={classes.imageBox}
            src={imageServerUrl + coverUrl}
            alt="card"
          />
        </Box>
        <Box className={classes.RightBox}>
          <TitleText isCursorPointer={true}>{title}</TitleText>
          {isRedactor && (
            <Box className={classes.AuthorBox}>
              <Typography className={classes.authorTextOption} component="span">
                Author: {agent.name} {agent.surname}
              </Typography>
              <Typography className={classes.authorTextOption} component="span">
                E-Mail: {agent.email_corp}
              </Typography>
              <Typography className={classes.authorTextOption} component="span">
                Tel: {agent.phone}
              </Typography>
            </Box>
          )}
          <Box className={classes.OptionCard}>
            {status === "PUBLISHED" && <div></div>}
            {!(status === "PUBLISHED") && (
              <Box className={classes.statusCard}>
                <Typography className={classes.textOption} component="span">
                  Status:
                  <TextStatus status={status}>
                    {(() => {
                      if (status === "DRAFT") {
                        return " Entwurf";
                      } else if (status === "REJECT") {
                        return " Abgelehnt";
                      } else if (status === "EXAMINATION") {
                        return " In Prüfung";
                      } else if (status === "PUBLISHED") {
                        return " Veröffentlicht";
                      }
                    })()}
                  </TextStatus>
                </Typography>
              </Box>
            )}
            <Box className={classes.editCard}>
              {status === "PUBLISHED" && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={stateSwitcher}
                      onChange={handleSwitcher}
                      color="primary"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      disabled={isRedactor}
                    />
                  }
                  label=" Sichtbarkeit"
                />
              )}
              {!isRedactor && (
                <Tooltip title="Löschen" aria-label="Löschen">
                  <DeleteOutlineOutlinedIcon
                    onClick={handleClickOpen}
                    className={classes.icon}
                  />
                </Tooltip>
              )}
              {(status === "DRAFT" || status === "REJECT") && (
                <Tooltip
                  title="Zur Moderation senden"
                  aria-label="Zur Moderation senden"
                >
                  <PublishOutlinedIcon
                    className={classes.icon}
                    onClick={handleSetStoryToEXAMINATION}
                  />
                </Tooltip>
              )}
              {!isRedactor && (
                <div>
                  {status === "EXAMINATION" && (
                    <Tooltip
                      title="Von der Moderation abholen"
                      aria-label="Von der Moderation abholen"
                    >
                      <GetAppOutlinedIcon
                        className={classes.icon}
                        onClick={handleSetStoryToDRAFT}
                      />
                    </Tooltip>
                  )}
                </div>
              )}
              {!(status === "PUBLISHED" || status === "EXAMINATION") && (
                <Tooltip title="Bearbeiten" aria-label="Bearbeiten">
                  <EditOutlinedIcon
                    onClick={() => {
                      history.push(modURL + id);
                    }}
                    className={classes.icon}
                  />
                </Tooltip>
              )}
              {!isRedactor && (
                <div>
                  {status === "PUBLISHED" && (
                    <Tooltip title="Bearbeiten" aria-label="Bearbeiten">
                      <EditOutlinedIcon
                        onClick={handlerDuplicateStory}
                        className={classes.icon}
                      />
                    </Tooltip>
                  )}
                </div>
              )}
              {isRedactor && (
                <Tooltip title="Bearbeiten" aria-label="Bearbeiten">
                  <EditOutlinedIcon
                    onClick={() => {
                      history.push(modURL + id);
                    }}
                    className={classes.icon}
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Achtung!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Wenn Sie einen Artikel löschen, kann er nicht wiederhergestellt
            werden.Sind Sie sicher, dass Sie den Artikel löschen möchten?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SecondaryButtonMedium onClick={handleClose} color="primary">
            Abbrechen
          </SecondaryButtonMedium>
          {!(status === "PUBLISHED") && (
            <ErrorButtonMedium onClick={handleDeleteStory} color="primary">
              <DeleteOutlineOutlinedIcon />
              Artikel löschen
            </ErrorButtonMedium>
          )}
          {status === "PUBLISHED" && (
            <ErrorButtonMedium
              onClick={handleDeletePublishedStory}
              color="primary"
            >
              <DeleteOutlineOutlinedIcon />
              Entfernen
            </ErrorButtonMedium>
          )}
        </DialogActions>
      </Dialog>
      <AlertMessage
        typeMessage={typeMessage}
        alertMessage={alertMessage}
        isAlertMessage={isAlertMessage}
        handleChangeIsAlertMessage={handleChangeIsAlertMessage}
      />
    </ThemeProvider>
  );
}
