import jwt_decode from 'jwt-decode';

export default function getCurrentUser() {
  const userToken = localStorage.getItem('token');
  if (userToken) {
    if (jwt_decode(userToken).exp > Math.floor(Date.now() / 1000)) {
      return jwt_decode(userToken);
    } else {
      localStorage.removeItem('token');
      window.location = '/mypage';
      return false;
    }
  } else {
    return false;
  }
}
