import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import Header from "../components/blocks/Header";
import ThemeOption from "../utilites/config/themeOption";

interface Props {
  isRedactor: boolean;
}

export default function WikiPage({ isRedactor }: Props) {
  return (
    <ThemeProvider theme={ThemeOption}>
      <Header isRedactor={isRedactor} />
      <iframe
        style={{ position: "absolute", height: "100%", border: "none" }}
        title="WikiFrame"
        width="100%"
        height="100%"
        src={
          process.env.NODE_ENV === "development"
            ? "http://dev.rasum.net:4004/login"
            : `https://wiki.mein-urlaubsglueck.de/login`
        }
      />
    </ThemeProvider>
  );
}
