import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ThemeOption from '../../utilites/config/themeOption';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  OverLay: ({ show }: LoaderProps) => ({
    display: show ? 'block' : 'none',
    zIndex: 1000,
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
  }),
  LoaderBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '200px',
    height: '50px',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  LoaderText: {
    color: '#fff',
    fontSize: '30px',
    fontFamily: "'Poppins', sans-serif",
  },
}));

interface LoaderProps {
  show: Boolean;
}

export default function LoadingOverlay({ show }: LoaderProps) {
  const props: LoaderProps = { show };
  const classes = useStyles(props);

  return (
    <ThemeProvider theme={ThemeOption}>
      <Box className={classes.OverLay}>
        <Box className={classes.LoaderBox}>
          <Typography className={classes.LoaderText}>
            Bitte warten...
          </Typography>
          <CircularProgress />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
