import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import { EmailOutline } from '@styled-icons/evaicons-outline/EmailOutline';
import { Phone } from '@styled-icons/bootstrap/Phone';
import { At } from '@styled-icons/boxicons-regular/At';
import { Speakerphone } from '@styled-icons/heroicons-outline/Speakerphone';
import H6 from '../UI/Typography/Heading6';
import Customer from '../../types/Customer.types';
import CircularProgress from '@material-ui/core/CircularProgress';
import ThemeOption from '../../utilites/config/themeOption';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    width: '570px',
    height: '525px',
    overflow: 'scroll',
  },
  tableRaw: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '57px',
    marginTop: '15px',
    borderBottom: '1px solid #DADADA',
    backgroundColor: ({ isSelected }: ComponentInput) => {
      if (isSelected) {
        return '#D0E3F2';
      } else {
        return 'white';
      }
    },
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#D0E3F2' },
  },
  loading: {
    position: 'absolute',
    top: '20%',
    left: '48%',
  },
}));

interface ComponentInput {
  list: Array<Customer>;
  onSelectedItem?: Function;
  isSelected?: boolean;
  isLoading: boolean;
}

export default function CustomerList({
  list,
  onSelectedItem,
  isLoading,
}: ComponentInput) {
  const props: ComponentInput = {
    list,
    onSelectedItem,
    isLoading,
  };
  const classes = useStyles(props);

  return (
    <Box className={classes.mainContainer}>
      {isLoading && (
        <Box className={classes.loading}>
          <CircularProgress />
        </Box>
      )}
      {list.map((item) => {
        return (
          <Box
            className={classes.tableRaw}
            key={item._id}
            onClick={() => {
              if (onSelectedItem) onSelectedItem(item);
            }}
          >
            <Box>
              <H6>
                {item.name} {item.surname}
              </H6>
            </Box>
            <Box>
              <EmailOutline
                color={item.mailing ? ThemeOption.palette.primary.main : 'gray'}
                width={20}
                height={20}
              />
              <Phone
                color={
                  item.phoneCalls ? ThemeOption.palette.primary.main : 'gray'
                }
                width={20}
                height={20}
              />
              <At
                color={
                  item.digitalCommunication
                    ? ThemeOption.palette.primary.main
                    : 'gray'
                }
                width={20}
                height={20}
              />
              <Speakerphone
                color={
                  item.newsletter ? ThemeOption.palette.primary.main : 'gray'
                }
                width={20}
                height={20}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
