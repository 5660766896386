const updateSite = `mutation($input:InputSite!, $id:String!) {
  updateSite(
        input: $input
        id: $id
  )	{
    agent {
        name
        surname
      }
      route
      seo_description
      tourTheme {
        _id
        title
        subtitle
        description
        image
      }
      phone
      agent_image
      stroys {
        _id
        title
        text
        seo_description
        seo_keywords
        themes {
          _id
          title
          subtitle
          description
          image
        }
        images
        status
        updatedAt
        is_aktive
        reject_reason
      }
      is_aktive
      insta_link
      facebook_link
      aboutMe
      isWhatsApp
    }
  }`;

export default updateSite;
