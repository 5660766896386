import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const CustomButton = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 20,
    padding: "8px 16px",
    // borderRadius: "8px",
    borderRadius: theme.btn?.borderRadius,
    lineHeight: 1.2,
    background: theme.palette.secondary.main,
    color: theme.typography.textcolor,
    // color: `green`,
    fontFamily: ['"Poppins", sans-serif'].join(","),
    "&:hover": {
      background: theme.palette.secondary.light,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      background: theme.palette.secondary.dark,
    },
    "&:focus": {},
  },
}))(Button);

export default function PrimaryButtonMedium(prop) {
  if (prop.children === "Abbrechen") {
    return (
      <CustomButton
        onClick={prop.onClick}
        type={prop.type}
        disabled={prop.disabled}
        variant="contained"
        style={{ background: "#D93D23" }}
      >
        {prop.children}
      </CustomButton>
    );
  } else if (prop.children === "Speichern") {
    return (
      <CustomButton
        onClick={prop.onClick}
        type={prop.type}
        disabled={prop.disabled}
        variant="contained"
        style={{ background: "#5c9724" }}
      >
        {prop.children}
      </CustomButton>
    );
  } else if (prop.children === `Anmelden`) {
    return (
      <CustomButton
        onClick={prop.onClick}
        type={prop.type}
        disabled={prop.disabled}
        variant="contained"
        style={{
          background: "#889EAF",
        }}
      >
        {prop.children}
      </CustomButton>
    );
  } else {
    return (
      <CustomButton
        onClick={prop.onClick}
        type={prop.type}
        disabled={prop.disabled}
        variant="contained"
      >
        {prop.children}
      </CustomButton>
    );
  }
}
