import themeMugl from "./themeMugl";
import themeTravelista from "./themeTravelista";
import themeAER from "./themeAER";

/**
 * Function to chose the theme for app depend on office_id of user
 * @param {Number} office_id
 * @returns {Object}  Object with theme options
 */

export default function themeChoserForOffice(office_id) {
  let themeVariation = null;

  switch (office_id) {
    case 1:
      themeVariation = themeMugl;
      break;
    case 2:
      themeVariation = themeTravelista;
      break;
    case 3:
      themeVariation = themeAER;
      break;
    default:
      themeVariation = themeMugl;
  }

  return themeVariation;
}
