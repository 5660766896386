export enum Laender {
  deutschland = 1,
  italien = 2,
  spanien = 3,
  oesterreich = 4,
}

export const COUNTRYCODE = [
  { id: 1, countryName: 'Deutschland', code: 'DE' },
  { id: 2, countryName: 'Italien', code: 'IT' },
  { id: 3, countryName: 'Spanien', code: 'ES' },
  { id: 4, countryName: 'Österreich', code: 'AT' },
];
