import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { URLForGit } from '../../modules/services/URLForGit';

const splited = URLForGit.split('/');
export const imageServerUrl = 'https://' + splited[0] + '/images/storys/';
export const imageServerUrlAgent = 'https://' + splited[0] + '/images/agents/';
export const AgentThumbnailUrl =
  'https://' + splited[0] + '/images/agents/thumbnails/';
export const url = 'https://' + splited[0] + '/backend';
export const urlEkuko = 'http://localhost:3000/backend';
export const mainUrl = 'https://' + splited[0];
export const TOKEN = '';

export const minImagesStory = 3;
export const minThemesUser = 4;

export const config = {
  uri: 'https://cockpit.mein-urlaubsglueck.de/ekuko-backend',
};

export const configForGuest = {
  uri: 'https://cockpit.mein-urlaubsglueck.de/ekuko-backend',
};

const httpLink = createHttpLink({
  uri: configForGuest.uri,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTYzMjIxNTEsImV4cCI6NTkzNjMyMjE1MX0.P-jqfVlSSpomxl7_crnGN1Sa-gY5zzXtLJXegJPSUJ0QO4AbbTWTaSahj-IRYLqkabo4x4aLVjqLZQyOUhZlkSW_L9hv2JQ7y9cKlozCm3NjNjQsBkME4sgUywV7YoGHuLDweTdnjXgvblKCL0hv871Ypny3VpkhIUWKf0wJzLFYl1m10fpgl_1lEw1hAVZlQ-UJIixmSviEvBYrnNJmtHkkWx2tMOHafhkfmUpfNSaaOSX0m_YNlgOILsF6w9QJNT0026aik1Uz1Z7hjXMtzwfcOt83j99vWW4S9YW7rMUFmwVWMZRRqPbavQp1WJE7OGsUkpzc1ApfArS_6MNjEQ`,
    },
  };
});

export const guestClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
