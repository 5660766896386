import { url } from "./config/config";
import { TOKEN } from "./config/config";

let token = (localStorage.getItem('token') !== null) ? localStorage.getItem("token") : TOKEN;

const uploadStoryImages = async (id, image) => {
  console.log("Start create image");
  console.log("tourId ", id);
  console.log("cover", image);

  const queryBody = `{"query":"mutation($image: Upload!){uploadStoryImages(id:\\"${id}\\", image:$image)}","variables":{"image":null},"operationName":null}`;
  const map = `{"0":["variables.image"]}`;
  let form = new FormData();
  form.append("operations", queryBody);
  form.append("map", map);
  form.append("0", image);

  return fetch(url, {
    headers: {
      authorization: "Bearer " + token,
    },
    method: "POST",
    body: form,
  })
    .then((responce) => responce.json())
    .then((result) => {
      console.log(result);
      return result;
    });
};

export default uploadStoryImages;


