import requestToServer from './requestToServer';
import InputSite from '../interfaces/InputSite.interface';
import updateSite from './queryBodies/updateSite';

export default async function handleUpdateSite(
  input: InputSite,
  id: string,
  handleError?: Function
): Promise<Boolean> {
  return await requestToServer('mutation', updateSite, { input, id })!
    .then((result) => {
      if (result.error) {
        if (handleError) handleError(result.error);
        return false;
      }
      return true;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
}
