import React from 'react';
import styled from 'styled-components';

const Body18 = styled.span`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => {
    if (props.color) {
      return props.color;
    } else {
      return '#383838';
    }
  }};
`;

export default function TextBody18({ children, color }) {
  return <Body18 color={color}>{children}</Body18>;
}
