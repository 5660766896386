import { EkukoIntranetLink } from '../interfaces/EkukoIntranetLink.interface';

const env = process.env.NODE_ENV;

const ekukoLinks: EkukoIntranetLink[] = [
  {
    id: 1,
    link:
      env === 'development'
        ? 'http://localhost:3000/mypage/ekuko/'
        : 'https://cockpit.mein-urlaubsglueck.de/mypage/ekuko/',
  },
  {
    id: 3,
    link:
      env === 'development'
        ? 'http://localhost:3000/mypage/ekuko/'
        : 'https://cockpit.mein-urlaubsglueck.de/mypage/ekuko/',
  },
  {
    id: 4,
    link:
      env === 'development'
        ? 'http://localhost:3000/ekuko/'
        : 'https://cockpit.my-travelexpert.de/mypage/ekuko/',
  },
];

export default ekukoLinks;
