import { gql } from '@apollo/client';

const getUser = gql`
  query {
    getUser {
      id
      email
      name
      photo
      surname
      customers {
        lastRequest
        _id
        name
        surname
        email
        salutation
        mailing
        phoneCalls
        digitalCommunication
        newsletter
        statusAccept
        dateAccept
        lastRequest
        notice
        is_male
        regularCustomer
      }
    }
  }
`;
export default getUser;
