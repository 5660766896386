import requestToServer from './requestToServer';
import updateTempAgentQuery from './queryBodies/updateTempAgent';
import inputTempAgent from '../interfaces/inputTempAgent.interface';

export default async function handleUpdateTempAgent(
  input: inputTempAgent,
  handleError?: Function
): Promise<Boolean> {
  return await requestToServer('mutation', updateTempAgentQuery, { input })!
    .then((result) => {
      if (result.error) {
        if (handleError) handleError(result.error);
        return false;
      }
      return true;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
}
